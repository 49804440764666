<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();
const  hoveredItemId = ref<string | null>(null);

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 730, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1500, end: rectForFirst.y + 1640 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};
const adjustMiddleLineHeight = () => {

  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement;
  const rightSection = divManipulatorRef.value?.querySelector(".right-section") as HTMLElement;
  const middleLine = divManipulatorRef.value?.querySelector(".custom-vertical-line") as HTMLElement;

  if (leftSection && rightSection && middleLine) {
    const leftHeight = leftSection.getBoundingClientRect().height;
    const rightHeight = rightSection.getBoundingClientRect().height;

    const lineHeight = Math.max(leftHeight, rightHeight) -30;

    middleLine.style.height = `${lineHeight}px`;
  }
};

const handleResize = () => {
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}
};

watch(() => cvData, () => {
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}

}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {

  if(!adjust)
  {
    await nextTick(adjustMiddleLineHeight);
    updateForbiddenZones();
    adjustLayout(forbiddenZones.value, divManipulatorRef);}

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ '--resume-color': cvData.resumeColor, fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="top-section section-hoverable"
           :class="{ highlighted: hoveredItemId === `contact-information` }"
           @mouseover="hoveredItemId = `contact-information`"
           @mouseleave="hoveredItemId = null"
           @click="$emit('selectSectionItem', { section: 'personal', itemId: null })"
      >
        <div class="fullname-text-contact">{{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}</div>
        <div class="organization-text">{{ cvData.contact?.jobTitle }}</div>
        <div class="contacts-row">
          <p v-if="cvData.contact?.birthDate" class="organization-text"><i class="fa fa-birthday-cake"></i> {{ cvData.contact?.birthDate }}</p>
          <p v-if="cvData.contact?.phone" class="organization-text"><i class="fas fa-phone"></i> {{ cvData.contact?.phone }}</p>
        </div>
        <div class="contacts-row">
          <p v-if="cvData.contact?.email" class="organization-text"><i class="fas fa-envelope"></i> {{ cvData.contact?.email }}</p>
          <p v-if="cvData.contact?.address || cvData.contact?.country || cvData.contact?.city" class="organization-text"><i class="fas fa-map-marker-alt"></i>
            {{ cvData.contact.country }}{{ cvData.contact.country && cvData.contact?.city ? ',' : '' }}{{ cvData.contact.city }}{{ cvData.contact.city && cvData.contact?.address ? ',' : '' }}{{ cvData.contact?.address }}
          </p>
        </div>
        <div class="introduction-text" v-html="cvData.contact?.description"></div>
      </div>
      <div class="custom-line-long"></div>
      <div class="bottom-section">
        <div class="left-section">
          <div v-if="cvData.educations && cvData.educations.length > 0" class="main-section-centered">
            <div class="section-title">
              <div class="section-title-text-left" :style="{ color: cvData.resumeColor }">{{ t('Education') }}</div>
            </div>
            <div class="education-main-section-left">
              <div v-for="education in cvData.educations" :key="education.id"
                   class="education-section-elements section-hoverable"
                   :class="{ highlighted: hoveredItemId === `educations-${education.id}` }"
                   @mouseover="hoveredItemId = `educations-${education.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'educations', itemId: education.id })"
              >
                <div class="inline-container">
                  <div style="font-weight: 600;" class="title-text">{{ education.school }}</div>
                  <div v-if="education.location" class="title-text">{{ education.location }}</div>
                  <div v-if="education.degree" class="title-text">{{ education.degree }}</div>
                  <div class="title-text">
                    {{ education.startDate || education.endDate
                      ? (education.startDate || '') + (education.startDate && education.endDate ? ' - ' : '') + (education.endDate || '')
                      : ''
                    }}
                  </div>
                  <div
                    v-if="education.description && containsTextOutsideHtml(education.description)"
                    class="description-div"
                  >
                    <div class="description-text" v-html="education.description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-section-combined">
            <div class="main-section">
              <div v-if="cvData.skills && cvData.skills.length > 0" class="main-section-left">
                <div class="section-title">
                  <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Skills') }}</div>
                </div>
                <div v-for="skill in cvData.skills" :key="skill.id"
                     class="skill-row section-hoverable"
                     :class="{ highlighted: hoveredItemId === `skills-${skill.id}` }"
                     @mouseover="hoveredItemId = `skills-${skill.id}`"
                     @mouseleave="hoveredItemId = null"
                     @click="$emit('selectSectionItem', { section: 'skills', itemId: skill.id })"
                >
                  <div class="organization-div-side">
                    <div style="font-weight: 600;" class="description-text">{{ skill?.name }}</div>
                    <div v-if="skill.level && cvData.showSkillLevels" class="description-text">{{ t(skill.level) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-section">
              <div v-if="cvData.languages && cvData.languages.length > 0" class="main-section-left">
                <div class="section-title">
                  <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Languages') }}</div>
                </div>
                <div v-for="languages in cvData.languages" :key="languages.id"
                     class="skill-row section-hoverable"
                     :class="{ highlighted: hoveredItemId === `languages-${languages.id}` }"
                     @mouseover="hoveredItemId = `languages-${languages.id}`"
                     @mouseleave="hoveredItemId = null"
                     @click="$emit('selectSectionItem', { section: 'languages', itemId: languages.id })"
                >
                  <div class="organization-div-side">
                    <div style="font-weight: 600;" class="description-text">{{ languages?.name }}</div>
                    <div v-if="languages.level && cvData.showLanguageLevels" class="description-text">{{ t(languages.level) }}</div>
                  </div>
                </div>
              </div>
              <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="main-section-centered">
                <div class="section-title">
                  <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Hobbies') }}</div>
                </div>
                <div class="main-section-left">
                  <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="section-elements">
                    <div v-for="hobby in cvData.hobbies" :key="hobby.id"
                         class="description-div section-hoverable"
                         :class="{ highlighted: hoveredItemId === `hobbies-${hobby.id}` }"
                         @mouseover="hoveredItemId = `hobbies-${hobby.id}`"
                         @mouseleave="hoveredItemId = null"
                         @click="$emit('selectSectionItem', { section: 'hobbies', itemId: hobby.id })"
                    >
                      <div class="description-text" v-html="hobby.description"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-vertical-line"></div>
        <div class="right-section">
          <div v-if="cvData.experiences && cvData.experiences.length > 0" class="main-section-centered">
            <div class="section-title">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Experience') }}</div>
            </div>
            <div v-for="experience in cvData.experiences" :key="experience.id"
                 class="section-elements section-hoverable"
                 :class="{ highlighted: hoveredItemId === `experiences-${experience.id}` }"
                 @mouseover="hoveredItemId = `experiences-${experience.id}`"
                 @mouseleave="hoveredItemId = null"
                 @click="$emit('selectSectionItem', { section: 'experiences', itemId: experience.id })"
            >
              <div class="organization-div-side">
                <div v-if="experience.title" class="title-text-experience">{{ experience.title }}</div>
                <div v-if="experience.startDate || experience.endDate" class="title-text">{{ experience?.startDate ? experience?.startDate : '' }}{{ experience?.endDate ? ' - ' + experience.endDate : '' }}</div>
                <div v-if="experience.company" class="title-text">{{ experience.company }}</div>
                <div v-if="experience.location" class="title-text">{{ experience.location }}</div>
              </div>
              <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="description-div">
                <div class="description-text" v-html="experience.description"></div>
              </div>
            </div>
          </div>
          <div v-if="cvData.projects && cvData.projects.length > 0" class="main-section-centered">
            <div class="section-title">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Projects') }}</div>
            </div>
            <div v-for="project in cvData.projects" :key="project.id"
                 class="section-elements section-hoverable"
                 :class="{ highlighted: hoveredItemId === `projects-${project.id}` }"
                 @mouseover="hoveredItemId = `projects-${project.id}`"
                 @mouseleave="hoveredItemId = null"
                 @click="$emit('selectSectionItem', { section: 'projects', itemId: project.id })"
            >
              <div class="organization-div-side">
                <div v-if="project.title" class="title-text-experience">{{ project.title }}</div>
                <div v-if="project.startDate || project.endDate" class="title-text">{{ project?.startDate ? project?.startDate : '' }}{{ project?.endDate ? ' - ' + project.endDate : '' }}</div>
              </div>
              <div v-if="project.description && containsTextOutsideHtml(project.description)" class="description-div">
                <div class="description-text" v-html="project.description"></div>
              </div>
            </div>
          </div>
          <div class="main-section-centered">
            <div v-if="cvData.certifications && cvData.certifications.length > 0" class="references">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Certifications') }}</div>
              <div v-for="certification in cvData.certifications" :key="certification.id"
                   class="section-elements section-hoverable"
                   :class="{ highlighted: hoveredItemId === `certifications-${certification.id}` }"
                   @mouseover="hoveredItemId = `certifications-${certification.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'certifications', itemId: certification.id })"
              >
                <div class="organization-text" style="font-weight: 600;">{{ certification?.course }}</div>
                <div class="description-text">{{ certification?.institute }}</div>
              </div>
            </div>
            <div v-if="cvData.references && cvData.references.length > 0" class="references">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('References') }}</div>
              <div v-for="reference in cvData.references" :key="reference.id"
                   class="section-elements section-hoverable"
                   :class="{ highlighted: hoveredItemId === `references-${reference.id}` }"
                   @mouseover="hoveredItemId = `references-${reference.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'references', itemId: reference.id })"
              >
                <div v-if="reference?.name?.length" class="organization-text">{{ reference?.name }}</div>
                <div v-if="reference?.company?.length" class="description-text">{{ reference?.company }}</div>
                <div v-if="reference?.phone?.length" class="description-text">{{ reference?.phone }}</div>
                <div v-if="reference?.email?.length" class="description-text">{{ reference?.email }}</div>
              </div>
            </div>
          </div>
          <div v-if="cvData.publications && cvData.publications.length > 0" class="main-section-centered">
            <div class="publications">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Publications') }}</div>
              <div v-for="publication in cvData.publications" :key="publication.id"
                   class="description-div section-hoverable"
                   :class="{ highlighted: hoveredItemId === `publications-${publication.id}` }"
                   @mouseover="hoveredItemId = `publications-${publication.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'publications', itemId: publication.id })"
              >
                <div class="description-text" v-html="publication?.description"/>
              </div>
            </div>
          </div>
          <div v-if="cvData.honors && cvData.honors.length > 0" class="main-section-centered">
            <div class="publications">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Honors & Awards') }}</div>
              <div v-for="honor in cvData.honors" :key="honor.id"
                   class="description-div section-hoverable"
                   :class="{ highlighted: hoveredItemId === `honors-${honor.id}` }"
                   @mouseover="hoveredItemId = `honors-${honor.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'honors', itemId: honor.id })"
              >
                <div class="description-text" v-html="honor?.description"/>
              </div>
            </div>
          </div>
          <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="main-section-centered">
            <div class="publications">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Voluntary Activities') }}</div>
              <div v-for="voluntary in cvData.voluntaries" :key="voluntary.id"
                   class="description-div section-hoverable"
                   :class="{ highlighted: hoveredItemId === `voluntaries-${voluntary.id}` }"
                   @mouseover="hoveredItemId = `voluntaries-${voluntary.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'voluntaries', itemId: voluntary.id })"
              >
                <div class="description-text" v-html="voluntary?.description"/>
              </div>
            </div>
          </div>
          <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="main-section-centered">
            <div class="publications">
              <div class="section-title-text-left" :style="{color: cvData.resumeColor}">{{ t('Exhibitions') }}</div>
              <div v-for="exhibition in cvData.exhibitions" :key="exhibition.id"
                   class="description-div section-hoverable"
                   :class="{ highlighted: hoveredItemId === `exhibitions-${exhibition.id}` }"
                   @mouseover="hoveredItemId = `exhibitions-${exhibition.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'exhibitions', itemId: exhibition.id })"
              >
                <div class="description-text" v-html="exhibition?.description"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.section-hoverable {
  transition: border 0.2s;
}

.section-hoverable.highlighted {
  border: 1px solid blue;
  background-color: rgb(177, 223, 223);
  cursor: pointer !important;
}

.resume {
  width: 595px;
  max-width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 700px;
}

.top-section {
  background-color: white;
  box-sizing: border-box;
  padding-top: 20px;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bottom-section {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contacts-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.languages, .hobbies, .honors, .publications, .references, .skills, .certifications {
  margin-top: 10px;
  text-align: flex-start;
  display: flex;
  flex-direction: column;
  font-size: var(--global-font-size);
}
.education-main-section-left .education-section-elements:last-child,
.references .section-elements:last-child,
.certifications .section-elements:last-child,
.experience .section-elements:last-child,
.projects .section-elements:last-child,
.honors .section-elements:last-child,
.voluntaries .section-elements:last-child,
.exhibitions .section-elements:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.organization-div-side {
  display: flex;
  align-items: start;
  width: 100%;
  flex-direction: column;
}
.organization-text {
  margin: 2px;
  text-align: start !important;
  font-size: var(--global-font-size);
}
.left-section {
  width: 50%;
  background-color: white;
  box-sizing: border-box;
  padding: 10px;
  padding-top: 0px;
  text-align: start !important;
  align-items: center;
}
.contact-basics-div {
  padding-top: 50px;
  text-align: center;
}
.name-text-contact, .fullname-text-contact {
  font-weight: 700;
  font-size: var(--global-font-size);
  line-height: 27.24px;
  color: #333333;
  text-align: left;
  padding-left: 5px;
}
.fullname-text-contact {
  font-size: calc(var(--global-font-size) + 8px);
  text-align: center;
}

.right-section {
  width: 50%;
  box-sizing: border-box;
  padding-left: 15px;
  min-height: 98vh;
  text-align: start !important;
}
.h3-sections, .h3-sections-multiple {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.h3-sections-multiple {
  flex-direction: column;
}

ul {
  padding-left: 10px;
}

li {
  margin-bottom: 5px;
}
.introduction-div {
  margin-bottom: 20px;
}

.introduction-text {
  padding-left: 10px;
  padding-right: 10px;
  font-size: var(--global-font-size);
  line-height: 1.5;
  color: #333;
}
.custom-line-long {
  height: 3px;
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--resume-color);
}
.custom-line {
  border-bottom: 3px solid #ccc;
  margin: 20px 0;
  width: 350px;
  box-sizing: border-box;
  background-color: var(--resume-color);
}
.custom-line-left {
  border-bottom: 3px solid #ccc;
  margin: 10px 0;
  width: 170px;
  box-sizing: border-box;
  background-color: var(--resume-color);
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.main-section-centered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  margin-top: 10px;
}
.main-section-combined {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5px;

}

.main-section-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.section-title-text {
  font-size: calc(var(--global-font-size) + 6px);
  font-weight: 800;
  color: #333333;
}
.section-title-text-left {
  font-size: calc(var(--global-font-size) + 6px);
  font-weight: 800;
  color: #333333;
  padding-bottom: 5px;
}
.title-text {
  font-size: var(--global-font-size);
  color: #333333;
}
.title-text-experience {
  font-size: var(--global-font-size);
  color: #333333;
  font-weight: 600;
}
.section-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
}
.section-elements-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
}
.organization-text, .period-text {
  font-size: var(--global-font-size);
  color: #333333;
  text-align: left;
}
.description-text {
  font-size: calc(var(--global-font-size) - 2px);
  color: #333333;
  font-weight: 400;
  margin-top: 3px !important;
}
.inline-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #333333;
  font-size: var(--global-font-size);
  text-align: flex-start;
}
.skill-row {
  display: flex;
  align-items: center;
  width: 100%;
}
.section-title {
  padding-bottom: 5px;
  text-align: center;
}

.education-main-section-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.education-section-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
  width: calc(50% - 10px);
  box-sizing: border-box;
  margin-right: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.education-section-elements:nth-child(2n) {
  margin-right: 0;
}
.custom-vertical-line {
  width: 3px;
  background-color: var(--resume-color);
  left: 50%;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.description-div *{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
