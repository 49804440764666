export const categoryMessages: Record<string, Record<string, string>> = {
  en: {
    unprofessionalLanguage: "Unprofessional language detected:",
    excessivePersonalInfo: "Excessive personal information detected:",
    exaggerations: "Exaggeration detected:",
    negativePhrasing: "Negative phrasing detected:",
    repetitiveWords: "Repetitive words (4+ Repetition) detected:",
    weakVerbs: "Weak verbs detected:",
    passiveVoice: "Passive voice detected:",
    foundedSkills: "Skills found in CV:",
    atsMatchedKeywords: "ATS matched keywords:",
    atsMissingKeywords: "Missing ATS keywords:",
    missingSections: "Missing sections in CV:",
    resumeLength: "Resume length issue:",
    nonStandardCharacters: "Non-standard characters detected:",
    excessiveSpecialChars: "Excessive special characters detected:",
    enoughBulletPoints: "Inconsistent or missing bullet points detected:",
    tablesDetected: "Tables detected, which may not be ATS-friendly:",
    imagesDetected: "Images detected, which are not ATS-friendly:",
    complexFormattingDetected: "Complex formatting tags detected:",
    missingImportantInfo: "Missing important information detected:",
    notEnoughSkills: "Not enough skills detected:",
    inconsistentFormatting: "Inconsistent formatting detected:",
    usefulLinks: "Missing Useful Link:",
    spellingProblems: "Spelling problems detected:",
    grammarProblems: "Grammar problems detected:",
    resumeDesignProblems: "Resume design problems detected:",
    longBulletPoints: "Long bullet points detected:",
    misssingQuatifyingImpactLines: "Missing quantifying impact lines detected:",
  },
  tr: {
    unprofessionalLanguage: "Profesyonel olmayan kelime tespit edildi:",
    excessivePersonalInfo: "Fazla kişisel bilgi tespit edildi:",
    exaggerations: "Abartılı ifade tespit edildi:",
    negativePhrasing: "Olumsuz ifade tespit edildi:",
    repetitiveWords: "Tekrarlayan kelimeler (4+ tekrar) tespit edildi:",
    weakVerbs: "Zayıf fiiller tespit edildi:",
    passiveVoice: "Pasiflik tespit edildi:",
    foundedSkills: "CV'de bulunan beceriler:",
    atsMatchedKeywords: "ATS ile eşleşen kelimeler:",
    atsMissingKeywords: "Eksik ATS anahtar kelimeleri:",
    missingSections: "CV'de eksik bölümler:",
    resumeLength: "CV'de uzunluk sorunu:",
    nonStandardCharacters: "Standart dışı karakterler tespit edildi:",
    excessiveSpecialChars: "Aşırı özel karakterler tespit edildi:",
    enoughBulletPoints: "Tutarsız veya eksik maddeler tespit edildi:",
    tablesDetected: "ATS uyumlu olmayan tablolar tespit edildi:",
    imagesDetected: "ATS uyumlu olmayan resimler tespit edildi:",
    complexFormattingDetected: "Karmaşık biçimlendirme etiketleri tespit edildi:",
    missingImportantInfo: "Önemli bilgi eksikliği tespit edildi:",
    notEnoughSkills: "Yeterli beceri tespit edilmedi:",
    inconsistentFormatting: "Tutarsız biçimlendirme tespit edildi:",
    usefulLinks: "Eksik Yararlı Bağlantı:",
    spellingProblems: "Yazım hataları tespit edildi:",
    grammarProblems: "Dilbilgisi sorunları tespit edildi:",
    resumeDesignProblems: "CV tasarım sorunları tespit edildi:",
    longBulletPoints: "Uzun madde işaretleri tespit edildi:",
    misssingQuatifyingImpactLines: "Eksik niceliksel etki alanları tespit edildi:",
  },
  de: {
    unprofessionalLanguage: "Unprofessionelle Sprache erkannt:",
    excessivePersonalInfo: "Übermäßige persönliche Informationen erkannt:",
    exaggerations: "Übertreibung erkannt:",
    negativePhrasing: "Negative Formulierungen erkannt:",
    repetitiveWords: "Wiederholende Wörter (4+ Wiederholung) erkannt:",
    weakVerbs: "Schwache Verben erkannt:",
    passiveVoice: "Passivstimme erkannt:",
    foundedSkills: "Im Lebenslauf gefundene Fähigkeiten:",
    atsMatchedKeywords: "Mit ATS übereinstimmende Schlüsselwörter:",
    atsMissingKeywords: "Fehlende ATS-Schlüsselwörter:",
    missingSections: "Fehlende Abschnitte im Lebenslauf:",
    resumeLength: "Problem mit der Lebenslauflänge:",
    nonStandardCharacters: "Nicht standardmäßige Zeichen erkannt:",
    excessiveSpecialChars: "Übermäßige Sonderzeichen erkannt:",
    enoughBulletPoints: "Inkonsistente oder fehlende Aufzählungszeichen erkannt:",
    tablesDetected: "Tabellen erkannt, die möglicherweise nicht ATS-freundlich sind:",
    imagesDetected: "Bilder erkannt, die nicht ATS-freundlich sind:",
    complexFormattingDetected: "Komplexe Formatierungstags erkannt:",
    missingImportantInfo: "Fehlende wichtige Informationen erkannt:",
    notEnoughSkills: "Nicht genügend Fähigkeiten erkannt:",
    inconsistentFormatting: "Inkonsistente Formatierung erkannt:",
    usefulLinks: "Fehlender nützlicher Link: ",
    spellingProblems: "Rechtschreibprobleme erkannt:",
    grammarProblems: "Grammatikprobleme erkannt:",
    resumeDesignProblems: "Probleme mit dem Lebenslaufdesign erkannt:",
    longBulletPoints: "Lange Aufzählungspunkte erkannt:",
    misssingQuatifyingImpactLines: "Fehlende quantifizierende Auswirkungslinien erkannt:",
  },
};

