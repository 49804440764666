<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
    t: (key: string) => string;
    jobTitles: string[];
    modelValue: string;
    showResults: boolean;
  }>();

const emit = defineEmits(["update:modelValue", "selectJobTitle", "deselectJobTitle"]);

const filteredJobTitles = computed(() => {
  const query = props.modelValue.trim().toLowerCase();
  if (!query) {
    return [];
  }
  return props.jobTitles.filter((jobTitle) =>
    jobTitle.toLowerCase().includes(query),
  );
});

const handleSelectJobTitle = (jobTitle: string) => {
  emit("update:modelValue", jobTitle);
  emit("selectJobTitle", jobTitle);
};

const handleDeselectJobTitle = () => {
  emit("update:modelValue", "");
  emit("deselectJobTitle");
};

const onInputChange = (event: Event) => {
  const input = event.target as HTMLInputElement;
  emit("update:modelValue", input.value);
};
</script>

<template>
  <div class="job-title-mobile-div">
    <div class="search-bar-mobile">
      <input
        :value="modelValue"
        :placeholder="t('cvEvaluation.DesiredJobTitle')"
        class="search-input-mobile"
        @input="onInputChange"
      />
      <button
        v-if="modelValue"
        class="deselect-button-mobile"
        @click="handleDeselectJobTitle"
      >
        X
      </button>
    </div>
    <ul v-if="showResults && filteredJobTitles.length" class="results-list-mobile">
      <li
        v-for="jobTitle in filteredJobTitles"
        :key="jobTitle"
        class="result-item-mobile"
        @click="handleSelectJobTitle(jobTitle)"
      >
        {{ jobTitle }}
      </li>
    </ul>
  </div>
</template>

<style>
.job-title-mobile-div {
    margin-bottom: 30px;
    margin-top: 30px;
}

.search-bar-mobile {
    display: flex;
    align-items: center;
    width: 100%;
    width: 350px;
}

.search-input-mobile {
    flex-grow: 1;
    height: 56px;
    padding: 0 44px 0 12px;
    box-sizing: border-box;
    font-size: 14px;
    border: 1px solid #5438da;
    border-radius: 8px;
}

.deselect-button-mobile {
    position: absolute;
    right: 40px;
    background: none;
    border: none;
    color: #909090;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.results-list-mobile {
    width: 350px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 1000;
    text-align: start;
}

.result-item-mobile {
    padding: 10px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 14px;
    color: #333;
}

</style>
