import type { Component } from "vue";
import { createApp } from "vue";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import type { Store } from "vuex";

import type { CvData, CvState } from "@/composables/types";
import i18n from "@/languages/i18n";

type  CurrentTemplate = {
  component: Component;
}

const cvDataToString = (cvData: CvData) => {
  let result = "";

  // Helper function to append key-value pairs to the result
  const appendKeyValue = (key: string, value: any) => {
    if (value !== undefined && value !== null && value !== "") {
      result += `${key}: ${value}\n`;
    }
  };

  // Helper function to handle arrays
  const appendArray = (key: string, array: any[]) => {
    if (array && array.length > 0) {
      result += `${key}:\n`;
      array.forEach((item, index) => {
        result += `  ${index + 1}. `;
        Object.entries(item).forEach(([subKey, subValue]) => {
          if (subKey !== "id" && subValue !== undefined && subValue !== null && subValue !== "") {
            result += `${subKey}: ${subValue}, `;
          }
        });
        result = result.slice(0, -2); // Remove the trailing comma and space
        result += "\n";
      });
    }
  };

  // Contact Information
  if (cvData.contact) {
    result += "Contact Information:\n";
    Object.entries(cvData.contact).forEach(([key, value]) => {
      appendKeyValue(`  ${key}`, value);
    });
  }

  // Education
  appendArray("Education", cvData.educations || []);

  // Experience
  appendArray("Experience", cvData.experiences || []);

  // Exhibitions
  appendArray("Exhibitions", cvData.exhibitions || []);

  // Projects
  appendArray("Projects", cvData.projects || []);

  // Skills
  appendArray("Skills", cvData.skills || []);

  // Certifications
  appendArray("Certifications", cvData.certifications || []);

  // Honors
  appendArray("Honors", cvData.honors || []);

  // Hobbies
  appendArray("Hobbies", cvData.hobbies || []);

  // Languages
  appendArray("Languages", cvData.languages || []);

  // Publications
  appendArray("Publications", cvData.publications || []);

  // References
  appendArray("References", cvData.references || []);

  // Voluntaries
  appendArray("Voluntaries", cvData.voluntaries || []);
  return result;
};

export const previewPdf = async (currentTemplate: CurrentTemplate, myStore: Store<CvState>, resumeData: CvData, download: boolean, singlePage: boolean) => {

  const scaleFactor = download ? 3 : 2;
  if(!singlePage){

    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.height = "auto";
    document.body.appendChild(tempDiv);

    const app = createApp(currentTemplate.component, { cvData: resumeData });
    app.use(myStore);
    app.use(i18n);
    app.mount(tempDiv);

    await new Promise(resolve => requestAnimationFrame(resolve));

    const canvas = await html2canvas(tempDiv, {
      scale: scaleFactor,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.98);

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const imgProps = pdf.getImageProperties(imgData);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    if (imgHeight <= pdfHeight) {
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    } else {
      while (heightLeft >= 0) {
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;

        if (heightLeft > 0) {
          pdf.addPage();
          position = -imgHeight + heightLeft;
        }
      }
    }

    const textContent = cvDataToString(resumeData);
    const lines = textContent.split("\n");

    const lineHeight = 1;

    pdf.setTextColor(0,0,0,0);

    const maxWidth = pdf.internal.pageSize.getWidth() - 20;

    lines.forEach((line, index) => {
      const wrappedText = pdf.splitTextToSize(line, maxWidth);

      wrappedText.forEach((textLine: string, lineIndex: any) => {
        pdf.text(textLine, 10, 10 + ((index + lineIndex) * lineHeight), { renderingMode: "invisible" });
      });

    });
    app.unmount();
    tempDiv.remove();

    if (download) {
      const firstName = resumeData.contact?.firstName?.length ? resumeData.contact?.firstName : "name";
      const lastName = resumeData.contact?.lastName?.length ? resumeData.contact?.lastName : "";
      const jobTitle = resumeData.contact?.firstName?.length ? resumeData.contact?.jobTitle : "";
      pdf.save(firstName + "_" + lastName + "_" + jobTitle + ".pdf");
    }

    return pdf.output("datauristring");
  }
  else{

    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.height = "auto";
    document.body.appendChild(tempDiv);

    const app = createApp(currentTemplate.component, { cvData: resumeData, adjust:"true" });
    app.use(myStore);
    app.use(i18n);
    app.mount(tempDiv);

    await new Promise(resolve => requestAnimationFrame(resolve));

    const canvas = await html2canvas(tempDiv, {
      scale: scaleFactor,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.98);

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const imgProps = pdf.getImageProperties(imgData);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    const longPdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    longPdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    const textContent = cvDataToString(resumeData);
    const lines = textContent.split("\n");

    const lineHeight = 1;

    longPdf.setTextColor(0,0,0,0);

    const maxWidth = pdf.internal.pageSize.getWidth() - 20;

    lines.forEach((line, index) => {

      const wrappedText = longPdf.splitTextToSize(line, maxWidth);

      wrappedText.forEach((textLine: string, lineIndex: any) => {
        longPdf.text(textLine, 10, 10 + ((index + lineIndex) * lineHeight), { renderingMode: "invisible" });
      });

    });

    app.unmount();
    tempDiv.remove();

    if (download) {
      const firstName = resumeData.contact?.firstName?.length ? resumeData.contact?.firstName : "name";
      const lastName = resumeData.contact?.lastName?.length ? resumeData.contact?.lastName : "";
      const jobTitle = resumeData.contact?.firstName?.length ? resumeData.contact?.jobTitle : "";
      longPdf.save(firstName + "_" + lastName + "_" + jobTitle + ".pdf");
    }

    return longPdf.output("datauristring");
  }
};

