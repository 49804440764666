<script setup lang="ts">
import { ref, onMounted, watch, onUnmounted, computed } from "vue";

import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import type { RenderTask } from "pdfjs-dist";
import ColorInput from "vue-color-input";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import "pdfjs-dist/web/pdf_viewer.css";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import {  analyticsCategories } from "@/composables/const";
import type { CvData } from "@/composables/types";
import { useAnalytics } from "@/composables/useAnalytics";
import myStore from "@/store/index";
import { cloneAndTransformCvData } from "@/utils/fixBulletPoints";
import { previewPdf } from "@/utils/previewPdf";
import { returnTemplateComponent } from "@/utils/templateComponent";

import DropdownSelect from "../components/DropdownSelect.vue";

import PreviewTemplateBebek from "./cv-preview/cv-preview-templates/PreviewTemplateBebek.vue";
import PreviewTemplateBussiness from "./cv-preview/cv-preview-templates/PreviewTemplateBussiness.vue";
import PreviewTemplateCasual from "./cv-preview/cv-preview-templates/PreviewTemplateCasual.vue";
import PreviewTemplateClassic from "./cv-preview/cv-preview-templates/PreviewTemplateClassic.vue";
import PreviewTemplate from "./cv-preview/cv-preview-templates/PreviewTemplateDefault.vue";
import PreviewTemplateFancy from "./cv-preview/cv-preview-templates/PreviewTemplateFancy.vue";
import PreviewTemplateLiner from "./cv-preview/cv-preview-templates/PreviewTemplateLiner.vue";
import PreviewTemplateModa from "./cv-preview/cv-preview-templates/PreviewTemplateModa.vue";
import PreviewTemplateProfessional from "./cv-preview/cv-preview-templates/PreviewTemplateProfessional.vue";
// eslint-disable-next-line import/no-unresolved
import "pdfjs-dist/build/pdf.worker";
import CvEvaluated from "./CvEvaluated.vue";
import CvEvaluator from "./CvEvaluator.vue";

GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.js";
type DoneCallback = () => void;
const { t } = useI18n();

const { trackEvent } = useAnalytics();

const screenWidth = ref(window.innerWidth);

const updateScreenWidth = () => {
  screenWidth.value = window.innerWidth;
};

const isModalVisible = ref(false);
const currentTemplate = ref({ component: PreviewTemplateClassic });
const { user } = useAuth();
const modalAuthenticationVisible = ref(false);
const modalUpgradePremiumVisible = ref(false);
const store = useStore();
const customColor =ref("");
const customColorPicked = ref(false);

const transformedCvData = ref<CvData>(store.state.cvData);

const selectedFont = ref("Roboto");
const fontOptions = [
  { name: "Roboto", style: "font-family: 'Roboto', sans-serif;" },
  { name: "Arial", style: "font-family: Arial, sans-serif;" },
  { name: "Helvetica", style: "font-family: Helvetica, sans-serif;" },
  { name: "Montserrat", style: "font-family: 'Montserrat', sans-serif;" },
  { name: "Times New Roman", style: "font-family: 'Times New Roman', serif;" },
];
const colorsOfTemplates = {
  default: ["#000000", "#ed7878", "#26850b", "#267bc8", "#d7a86b"],
  classic: ["#000000", "#ed7878", "#26850b", "#267bc8", "#d7a86b"],
  casual: ["#000000", "#4d9ea2", "#26850b", "#267bc8", "#d7a86b"],
  business: ["#b2d1ed", "#eec2ed", "#c2eee8", "#b8d9b7", "#b8b7d9"],
  fancy: ["#b2d1ed", "#eec2ed", "#c2eee8", "#b8d9b7", "#b8b7d9"],
  bebek: ["#9895ff", "#084C41", "#193a78", "#872f1e", "#242950"],
  liner: ["#ed7878", "#26850b", "#267bc8", "#d7a86b", "#242950"],
  moda: ["#ed7878", "#26850b", "#267bc8", "#d7a86b", "#242950"],
  professional: ["#000000", "#808080", "#000080", "#800000", "#808000"],
};

const colors = [
  { name: "#000000", cssClass: "palette-black" },
  { name: "#ed7878", cssClass: "palette-lightred" },
  { name: "#26850b", cssClass: "palette-green" },
  { name: "#267bc8", cssClass: "palette-blue" },
  { name: "#d7a86b", cssClass: "palette-tan" },
  { name: "#4d9ea2", cssClass: "palette-teal" },
  { name: "#b2d1ed", cssClass: "palette-lightblue" },
  { name: "#eec2ed", cssClass: "palette-lightpink" },
  { name: "#c2eee8", cssClass: "palette-aqua" },
  { name: "#b8d9b7", cssClass: "palette-lightgreen" },
  { name: "#b8b7d9", cssClass: "palette-lavender" },
  { name: "#9895ff", cssClass: "palette-periwinkle" },
  { name: "#084C41", cssClass: "palette-darkteal" },
  { name: "#193a78", cssClass: "palette-darkblue" },
  { name: "#872f1e", cssClass: "palette-darkred" },
  { name: "#242950", cssClass: "palette-indigo" },
  { name: "#808080", cssClass: "palette-grey" },
  { name: "#000080", cssClass: "palette-navy" },
  { name: "#800000", cssClass: "palette-maroon" },
  { name: "#808000", cssClass: "palette-olive" },
];

type TemplateName = keyof typeof colorsOfTemplates;
const selectedTemplateName = ref<TemplateName>(
  store.state.cvData.selectedTemplate.toLowerCase() as TemplateName,
);
const isColorName = (cvColor: string): boolean => {
  return colors.some(color => color.name === cvColor);
};

const fontSize = ref(16);

const increaseFontSize = () => {
  if ( fontSize.value < 20) {
    fontSize.value += 1;
  }
};

const decreaseFontSize = () => {
  if (fontSize.value > 14 ) {
    fontSize.value -= 1;
  }
};

watch(fontSize, (newSize) => {
  store.commit("updateResumeFontSize", {  data: newSize - 6 });
});

watch(selectedFont, (newFont) => {
  store.commit("updateResumeFontFamily", {  data: newFont });
});

defineProps<{
  evaluated: boolean,
  generalScore: number,
  generalScoreExplanation: string
}>();
const singlePage = ref(true);

const emit = defineEmits(["evaluate"]);

const openAuthenticationModal = () => {
  modalAuthenticationVisible.value = true;
};

const openUpgradePremiumModal = () => {
  modalUpgradePremiumVisible.value = true;
};

const showTemplateModal = () => {
  isModalVisible.value = true;
};

const evaluate = (cvText: string, jobTitle: string, doneCallback: DoneCallback) => {
  if(user.value.details?.accountStatus === "Free"){
    trackEvent("button_click", {
      category: analyticsCategories.UPGRADE,
      action: "click",
      label: "creation_evaluate_to_upgrade",
      value: 1,
    });
    openUpgradePremiumModal();
    return;
  }
  trackEvent("button_click", {
    category: analyticsCategories.CV_EVALUATION,
    action: "click",
    label: "creation_evaluate",
    value: 1,
  });
  emit("evaluate", cvText, jobTitle, doneCallback);
};

const download = async () => {

  if(user.value){
    if(user.value.details?.accountStatus === "Free"){
      trackEvent("button_click", {
        category: analyticsCategories.UPGRADE,
        action: "click",
        label: "creation_download_to_upgrade",
        value: 1,
      });
      openUpgradePremiumModal();
      return;
    }
    await previewPdf(currentTemplate.value, myStore,  transformedCvData.value, true, singlePage.value );
  }
  else{
    openAuthenticationModal();
  }
};

const scale = ref(1);
const showPreview = ref<boolean>(true);

onMounted( async () => {
  const containerWidth = document.querySelector(".cv-container")?.clientWidth || 0;
  const scaleFactor = containerWidth / 794;
  scale.value = scaleFactor < 1 ? scaleFactor : 1;
});

const pdfCanvas = ref<HTMLCanvasElement | null>(null);

const currentPage = ref(1);
const totalPages = ref(0);
const pdfDataUrl = ref<string | undefined>("");

let currentRenderTask: RenderTask | null = null;

const loadPdf = async (pdfDataUrl: string, pageNum: number) => {
  try {
    if (currentRenderTask) {
      currentRenderTask.cancel();
    }

    const doc = await getDocument(pdfDataUrl).promise;
    totalPages.value = doc.numPages;
    const page = await doc.getPage(pageNum);

    const viewport = page.getViewport({ scale: 10 });
    const canvas = pdfCanvas.value;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        currentRenderTask = page.render(renderContext);
        await currentRenderTask.promise;
        currentRenderTask = null;
      } else {
        console.error("Failed to get 2D context from canvas.");
      }
    } else {
      console.error("Canvas element is null.");
    }
  } catch (error) {
    console.error("Error loading PDF document:", error);
  }
};

const goToPrevPage = async () => {
  if (currentPage.value > 1) {
    currentPage.value--;

    loadPdf(pdfDataUrl.value as string, currentPage.value);
  }
};

const goToNextPage = async () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;

    loadPdf(pdfDataUrl.value as string, currentPage.value);
  }
};

onMounted(async () => {
  currentTemplate.value = returnTemplateComponent(transformedCvData.value);
  pdfDataUrl.value = await previewPdf(currentTemplate.value, myStore, transformedCvData.value, false, singlePage.value);
  loadPdf(pdfDataUrl.value as string, 1);
  currentPage.value = 1;
  showPreview.value = false;
  if(!isColorName(transformedCvData.value.resumeColor)){
    customColorPicked.value = true;
    customColor.value = transformedCvData.value.resumeColor;
  }
  selectedFont.value = transformedCvData.value.resumeFontFamily;
  fontSize.value = transformedCvData.value.resumeFontSize + 6;
  transformedCvData.value = cloneAndTransformCvData(transformedCvData.value);

});

watch(
  () => store.state.cvData,
  async () => {
    transformedCvData.value = cloneAndTransformCvData(store.state.cvData);
    currentTemplate.value = returnTemplateComponent(transformedCvData.value);
    pdfDataUrl.value = await previewPdf(currentTemplate.value, myStore, transformedCvData.value, false, singlePage.value);
    loadPdf(pdfDataUrl.value as string, 1);
    currentPage.value = 1;
  },
  {
    deep: true,
  },
);
const selectTemplate = async (templateName: string) => {
  selectedTemplateName.value = templateName as TemplateName;
  await new Promise(resolve => setTimeout(resolve, 500));
  switch (templateName) {
  case "classic":
    currentTemplate.value.component = PreviewTemplateClassic;
    store.commit("updateSelectedTemplate", {  data: "Classic" });
    break;
  case "casual":
    currentTemplate.value.component = PreviewTemplateCasual;
    store.commit("updateSelectedTemplate", {  data: "Casual" });
    break;
  case "business":
    currentTemplate.value.component = PreviewTemplateBussiness;
    store.commit("updateSelectedTemplate", {  data: "Business" });
    break;
  case "professional":
    return;
    // currentTemplate.value.component = PreviewTemplateProfessional;
    //store.commit("updateSelectedTemplate", {  data: "Professional" });
    //break;
  case "fancy":
    currentTemplate.value.component = PreviewTemplateFancy;
    store.commit("updateSelectedTemplate", {  data: "Fancy" });
    break;
  case "bebek":
    currentTemplate.value.component = PreviewTemplateBebek;
    store.commit("updateSelectedTemplate", {  data: "Bebek" });
    break;
  case "liner":
    currentTemplate.value.component = PreviewTemplateLiner;
    store.commit("updateSelectedTemplate", {  data: "Liner" });
    break;
  case "moda":
    currentTemplate.value.component = PreviewTemplateModa;
    store.commit("updateSelectedTemplate", {  data: "Moda" });
    break;
  default:
    currentTemplate.value.component = PreviewTemplate;
    store.commit("updateSelectedTemplate", {  data: "Default" });
  }
  isModalVisible.value = false;
  pdfDataUrl.value = await previewPdf(currentTemplate.value, myStore,  transformedCvData.value, false, singlePage.value );
  loadPdf(pdfDataUrl.value as string, 1);
  currentPage.value = 1;

};

const selectColor = (hexCode: string) => {
  customColorPicked.value = false;
  customColor.value = hexCode;
  store.commit("updateResumeColor", { data: hexCode });
};

const displayedColors = computed(() => {
  const templateColors = colorsOfTemplates[selectedTemplateName.value];
  return colors.filter(color => templateColors.includes(color.name));
});

const isBlurred = ref(false);

const handleKeydown = (event: KeyboardEvent) => {
  if (event.code === "PrintScreen" ||
      (event.metaKey || event.ctrlKey)
  ) {
    isBlurred.value = true;
  } else {
    isBlurred.value = false;
  }
};

const handleMouseEvents = (event: MouseEvent) => {
  if (event.button === 2 || (event.ctrlKey && event.button === 0)) {
    isBlurred.value = true;
  } else if (event.button === 0) {
    isBlurred.value = false;
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  document.addEventListener("mousedown", handleMouseEvents);
  window.addEventListener("resize", updateScreenWidth);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleKeydown);
  document.removeEventListener("mousedown", handleMouseEvents);
  window.removeEventListener("resize", updateScreenWidth);
});

const closeModal = () => {
  if(customColorPicked.value){
    store.commit("updateResumeColor", {  data: customColor.value });
  }
  isModalVisible.value = false;
};

watch(customColor, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    customColorPicked.value = true;
    store.commit("updateResumeColor", { data: newValue });
  }
});

type SectionSelectionPayload = {
  section: string;
  itemId: string;
};

const handleSelectSectionItem = ({ section, itemId }: SectionSelectionPayload) => {
  store.dispatch("setActiveSection", {
    sectionName: section,
    itemId,
  });
};

</script>

<template>
  <AuthModal :isVisible="modalAuthenticationVisible" @update:visible="modalAuthenticationVisible = $event" />
  <UpgradePremium v-if="modalUpgradePremiumVisible" showFeatures :premiumReasonText="t('UpgradePremium.UpgradeToPremiumText')" @close="modalUpgradePremiumVisible = false" />
  <div v-if="isModalVisible" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal()">&times;</span>
      <span class="select-template-text">{{ t("cvCreationView.SelectTemplate") }}</span>
      <div class="template-options">
        <div class="template-options-element" @click="selectTemplate('default')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'default' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templatePure.webp') + ')' }"/>
          <span>Pure</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('classic')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'classic' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templateMinimal.webp') + ')' }"/>
          <span>Minimal</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('casual')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'casual' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templateSleek.webp') + ')' }"/>
          <span>Sleek</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('business')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'business' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templateModern.webp') + ')' }"/>
          <span>Modern</span></div>
        <div class="template-options-element" @click="selectTemplate('fancy')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'fancy' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templateBold.webp') + ')' }"/>
          <span>Bold</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('bebek')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'bebek' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templateLuxe.webp') + ')' }"/>
          <span>Luxe</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('liner')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'liner' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templateFocus.webp') + ')' }"/>
          <span>Focus</span>
        </div>
        <div class="template-options-element" @click="selectTemplate('moda')">
          <div class="template-options-image"
               :class="{ selected: selectedTemplateName === 'moda' }"
               :style="{ backgroundImage: 'url(' + require('@/assets/templateNeat.webp') + ')' }"/>
          <span>Neat</span>
        </div>
        <div class="template-options-element">
          <div class="template-image-wrapper">
            <div class="template-options-image"
                 :class="{ selected: selectedTemplateName === 'professional' }">
            </div>
            <div class="coming-soon-overlay">{{ t('ComingSoon') }}</div>
          </div>
          <span>{{ t("cvCreationView.Professional") }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="icon-button-container-preview">
    <button class="showTemplateButton" @click="showTemplateModal">
      <div class="add-more">
        <font-awesome-icon :icon="['fas', 'file-circle-plus']" class="white-icon" />
        <p>{{ t("MoreTemplates") }}</p>
      </div>
    </button>
    <div class="middle-section">
      <div class="color-palettes">
        <button
          v-for="color in displayedColors"
          :key="color.name"
          :style="{
            backgroundColor: color.name,
            border: myStore.state.cvData.resumeColor === color.name && !customColorPicked ? '2px solid #000' : 'none',
            width: '28px',
            height: '27px',
            borderRadius: '50%',
          }"
          @click="selectColor(color.name)"
        ></button>
        <div :style="{  borderRadius: '50%', position: 'relative', marginTop: '5px' }"  >
          <div :style="{ border: customColorPicked ? '2px solid #000' : 'none', position: 'absolute',
                         top: '0',
                         left: '0',
                         width: '33px',
                         height: '33px',
                         borderRadius: '50%',
                         zIndex: 0,
                         marginTop: '3.5px ',
                         marginLeft: '3.15px ' }" />
          <svg fill="none" height="40" viewBox="0 0 1024 1024" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m912 512c0 70.215-18.482 139.192-53.59 200l-86.602-50c26.33-45.606 40.192-97.339 40.192-150z" fill="#f45542"/><path d="m858.387 712.04c-35.114 60.804-85.615 111.293-146.427 146.393l-49.99-86.608c45.609-26.325 83.484-64.192 109.82-109.795z" fill="#dd3242"/><path d="m711.963 858.431c-60.811 35.101-129.791 53.576-200.005 53.569l.01-100c52.661.006 104.396-13.851 150.005-40.177z" fill="#af2f79"/><path d="m112 512c0 70.215 18.482 139.192 53.59 200l86.602-50c-26.33-45.606-40.192-97.339-40.192-150z" fill="#2656ab"/><path d="m165.613 712.04c35.114 60.804 85.615 111.293 146.427 146.393l49.99-86.608c-45.609-26.325-83.484-64.192-109.82-109.795z" fill="#7943a3"/><path d="m312.037 858.431c60.811 35.101 129.791 53.576 200.005 53.569l-.01-100c-52.661.006-104.396-13.851-150.005-40.177z" fill="#a23ca2"/><path d="m112 512c0-70.215 18.482-139.192 53.59-200l86.602 50c-26.33 45.606-40.192 97.339-40.192 150z" fill="#019a85"/><path d="m165.613 311.96c35.114-60.804 85.615-111.293 146.427-146.393l49.99 86.608c-45.609 26.325-83.484 64.192-109.82 109.795z" fill="#01ae5c"/><path d="m312.037 165.569c60.811-35.101 129.791-53.576 200.005-53.569l-.01 100c-52.661-.006-104.396 13.851-150.005 40.177z" fill="#a1cf49"/><path d="m912 512c0-70.215-18.482-139.192-53.59-200l-86.602 50c26.33 45.606 40.192 97.339 40.192 150z" fill="#f97827"/><path d="m858.387 311.96c-35.114-60.804-85.615-111.293-146.427-146.393l-49.99 86.608c45.609 26.325 83.484 64.192 109.82 109.795z" fill="#fca313"/><path d="m711.963 165.569c-60.811-35.101-129.791-53.576-200.005-53.569l.01 100c52.661-.006 104.396 13.851 150.005 40.177z" fill="#ffe503" /></svg>
          <ColorInput v-model="customColor"/>
        </div>
      </div>

      <div class="dropdown-font-selecter">
        <DropdownSelect v-model="selectedFont" :options="fontOptions" />
        <div class="font-size-adjuster">
          <button class="adjust-button increase-button" @click="increaseFontSize">+</button>
          <span class="font-size-display" :style="{ fontSize: fontSize + 'px' }">Aa</span>
          <button class="adjust-button decrease-button" @click="decreaseFontSize">-</button>
        </div>
      </div>

    </div>
    <button class="download-button" @click="download">{{ t("DownloadCv") }}</button>
  </div>
  <CvEvaluator v-if="!evaluated" @evaluate="evaluate"/>
  <CvEvaluated v-if="evaluated" :general-score="generalScore" :general-score-explanation="generalScoreExplanation"/>

  <div style="display: flex; gap: 15px;">
    <button
      class="page-button"
      :class="{ 'grey-background': singlePage }"
      @click="() => singlePage = true"
    >
      {{ t("cvCreationView.SinglePage") }}
    </button>
    <button
      class="page-button"
      :class="{ 'grey-background': !singlePage }"
      @click="async () => {
        currentTemplate = returnTemplateComponent(transformedCvData);
        singlePage = false;
        pdfDataUrl = await previewPdf(currentTemplate, myStore, transformedCvData, false, singlePage);
        loadPdf(pdfDataUrl as string, 1);
        currentPage = 1;
      }"
    >
      {{ t("cvCreationView.MultiplePage") }}
    </button>

  </div>

  <div v-if="totalPages && !singlePage" class="cv-preview-canvas" :class="{ blurred: isBlurred }">
    <div class="navigate-buttons">
      <button :disabled="currentPage === 1" @click="goToPrevPage"><font-awesome-icon :icon="['fas', 'chevron-left']"/></button>
      <p class="page-text" >{{ currentPage }} / {{ totalPages }}</p>
      <button :disabled="currentPage === totalPages" @click="goToNextPage"><font-awesome-icon :icon="['fas', 'chevron-right']" /></button>
    </div>
    <div style="display: flex;">
      <canvas ref="pdfCanvas" class="cv-preview" :style="{ '--scale-factor': 0.80 }"></canvas>
    </div>

  </div>
  <div v-if="singlePage" class="cv-preview-single"  :class="{ blurred: isBlurred }" :style="{ '--scale-factor': screenWidth / 1512 }">
    <PreviewTemplate v-if="currentTemplate.component.__name === 'PreviewTemplateDefault'" :cv-data="transformedCvData" :adjust="true"  @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateClassic v-if="currentTemplate.component.__name === 'PreviewTemplateClassic'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateCasual v-if="currentTemplate.component.__name === 'PreviewTemplateCasual'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateBussiness v-if="currentTemplate.component.__name === 'PreviewTemplateBussiness'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateFancy v-if="currentTemplate.component.__name === 'PreviewTemplateFancy'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateBebek v-if="currentTemplate.component.__name === 'PreviewTemplateBebek'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateLiner v-if="currentTemplate.component.__name === 'PreviewTemplateLiner'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateModa v-if="currentTemplate.component.__name === 'PreviewTemplateModa'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
    <PreviewTemplateProfessional v-if="currentTemplate.component.__name === 'PreviewTemplateProfessional'" :cv-data="transformedCvData" :adjust="true" @selectSectionItem="handleSelectSectionItem"/>
  </div>
  <div style="padding-bottom: 100px;"></div>

</template>

<style>

.dropdown-font-selecter{
  display: flex;
  flex-direction: row;
  gap: 15px
}

.font-size-adjuster {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.adjust-button {
  background: none;
  border: none;
  color: #030303;
  font-size: 24px;
  cursor: pointer;
  font-family: "Poppins";
  line-height: 31px;
}

.increase-button:hover,
.decrease-button:hover {
  font-weight: bold;
}

.font-size-display {
  color: #030303;
  font-size: 24px;
  font-family: "Poppins";
  line-height: 31px;
}

.icon-button-container-preview{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin-bottom: 22px;
}

.middle-section{
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  gap: 5px;
}

.page-button{
  padding: 8px 16px;
  border: none;
  border-radius: 7px;
  background-color: #f2f2f2;
  cursor: pointer;
}

.grey-background {
  background-color: rgb(93, 93, 93);
  color: white;
}

.lock-icon{
  color: #808080;
  fill: #808080;
  font-size: 40px;
  position: absolute;
  width: 40px;
  height: 40px;
}

.color-input__box {
  width: 24px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin-top: 8.4px;
  margin-left: 8px;
}

.color-palettes{
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.template-options-image{
  width: 213px;
  height: 309px;
  border-radius: 12px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid rgba(153, 153, 153, 0.6);
}
.template-options-image.selected {
  border: 4px solid #5438da;
}

.template-options-element{
display: flex;
flex-direction: column;
width: 25%;
align-items: center;
justify-content: center;
gap: 10px;
margin: 20px;
}

.select-template-text{
  color: #030303;
  font-size: 24px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 28px;
}

.showTemplateButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 860px;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.template-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px;
  margin-top: 25px;
}

.cv-preview-canvas{
  height: 1000px;
  width: 100%;
}

.cv-preview {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin: auto;
  overflow: hidden;
  background: white;
  transform-origin: top center;
  transform: scale(var(--scale-factor));
  margin-bottom: 30px;
}

.cv-preview-single {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-top: 50px;
  overflow: hidden;
  background: white;
  transform-origin: top center;
  transform: scale(var(--scale-factor));
  margin-bottom: 30px;
}

.cv-preview h2, .cv-preview h3 {
  margin: 10px 0;
}

.cv-preview p {
  margin: 5px 0;
}

.navigate-buttons {
  display: flex;
  justify-content: center;
  align-items: center;

}

.navigate-buttons button {
  color: rgb(255, 255, 255);
  background-color: #909090;;
  border: none;
  padding-top: 50px;
  padding-bottom: 50px;
}
.navigate-buttons .page-text{
  color: rgb(255, 255, 255);
}

.navigate-buttons button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

@media print {
  .cv-preview {
    transform: scale(1);
  }
  .navigate-buttons {
    display: none;
  }
  .resume {
    width: 100%;
    max-width: 794px;
    margin: 0 auto;
    box-shadow: none;
  }
  .resume_left, .resume_right {
    width: 100%;
    padding: 20px;
    box-shadow: none;
    page-break-inside: avoid;
  }
  .r_skills, .r_hobbies, .r_education, .r_jobs, .r_projects {
    page-break-inside: avoid;
  }
}
.cv-preview-canvas.blurred {
  filter: blur(10px);
}
.cv-preview-single.blurred {
  filter: blur(10px);
}
.template-image-wrapper {
  position: relative;
  width: 213px;
  height: 309px;
}

.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 309px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  font-weight: bold;
  border-radius: 12px;
  z-index: 1;
  pointer-events: none;
}

/* For #000000 */
.palette-black {
  width: 28px;
  height: 27px;
  background-color: #000000;
  border-radius: 50%;
}

/* For #ed7878 */
.palette-lightred {
  width: 28px;
  height: 27px;
  background-color: #ed7878;
  border-radius: 50%;
}

/* For #26850b */
.palette-green {
  width: 28px;
  height: 27px;
  background-color: #26850b;
  border-radius: 50%;
}

/* For #267bc8 */
.palette-blue {
  width: 28px;
  height: 27px;
  background-color: #267bc8;
  border-radius: 50%;
}

/* For #d7a86b */
.palette-tan {
  width: 28px;
  height: 27px;
  background-color: #d7a86b;
  border-radius: 50%;
}

/* For #4d9ea2 */
.palette-teal {
  width: 28px;
  height: 27px;
  background-color: #4d9ea2;
  border-radius: 50%;
}

/* For #b2d1ed */
.palette-lightblue {
  width: 28px;
  height: 27px;
  background-color: #b2d1ed;
  border-radius: 50%;
}

/* For #eec2ed */
.palette-lightpink {
  width: 28px;
  height: 27px;
  background-color: #eec2ed;
  border-radius: 50%;
}

/* For #c2eee8 */
.palette-aqua {
  width: 28px;
  height: 27px;
  background-color: #c2eee8;
  border-radius: 50%;
}

/* For #b8d9b7 */
.palette-lightgreen {
  width: 28px;
  height: 27px;
  background-color: #b8d9b7;
  border-radius: 50%;
}

/* For #b8b7d9 */
.palette-lavender {
  width: 28px;
  height: 27px;
  background-color: #b8b7d9;
  border-radius: 50%;
}

/* For #9895ff */
.palette-periwinkle {
  width: 28px;
  height: 27px;
  background-color: #9895ff;
  border-radius: 50%;
}

/* For #084C41 */
.palette-darkteal {
  width: 28px;
  height: 27px;
  background-color: #084C41;
  border-radius: 50%;
}

/* For #193a78 */
.palette-darkblue {
  width: 28px;
  height: 27px;
  background-color: #193a78;
  border-radius: 50%;
}

/* For #872f1e */
.palette-darkred {
  width: 28px;
  height: 27px;
  background-color: #872f1e;
  border-radius: 50%;
}

/* For #242950 */
.palette-indigo {
  width: 28px;
  height: 27px;
  background-color: #242950;
  border-radius: 50%;
}

</style>
