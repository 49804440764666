<script setup lang="ts">
import {  ref, watch } from "vue";

import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import type { SectionScore } from "@/composables/types";
import { useAdditionalBackgroundColor } from "@/views/cv-creation-view/composables/useAdditionalBackgroundColor";

import  CvSectionScore  from "../../components/CvSectionScore.vue";
import { sections } from "../../const";
import type { AdditionalSectionKey } from "../../const";

import CertificationDetails from "./additional-details/CertificationDetails.vue";
import ExhibitionDetails from "./additional-details/ExhibitionDetails.vue";
import HobbyDetails from "./additional-details/HobbyDetails.vue";
import HonorDetails from "./additional-details/HonorDetails.vue";
import LanguageDetails from "./additional-details/LanguageDetails.vue";
import ProjectDetails from "./additional-details/ProjectDetails.vue";
import PublicationDetails from "./additional-details/PublicationDetails.vue";
import ReferenceDetails from "./additional-details/ReferenceDetails.vue";
import VoluntaryDetails from "./additional-details/VoluntaryDetails.vue";

const { t } = useI18n();
const store = useStore();

const {
  certificationBackgroundColor,
  projectBackgroundColor,
  languageBackgroundColor,
  honorBackgroundColor,
  referenceBackgroundColor,
  publicationBackgroundColor,
  hobbyBackgroundColor,
  exhibitionBackgroundColor,
  voluntaryBackgroundColor,
} = useAdditionalBackgroundColor();

const getBackgroundColor = (sectionName: string) => {
  switch (sectionName) {
  case "certifications":
    return certificationBackgroundColor.value;
  case "projects":
    return projectBackgroundColor.value;
  case "languages":
    return languageBackgroundColor.value;
  case "honors":
    return honorBackgroundColor.value;
  case "references":
    return referenceBackgroundColor.value;
  case "publications":
    return publicationBackgroundColor.value;
  case "hobbies":
    return hobbyBackgroundColor.value;
  case "exhibitions":
    return exhibitionBackgroundColor.value;
  case "voluntaries":
    return voluntaryBackgroundColor.value;
  default:
    return "rgb(212, 212, 212)";
  }
};

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
  scoreDetails: SectionScore | undefined
}>();

const additionalSections = ref<Record<AdditionalSectionKey, boolean>>({
  certifications: false,
  projects: false,
  honors: false,
  languages: false,
  publications: false,
  references: false,
  hobbies: false,
  exhibitions: false,
  voluntaries: false,
});

const toggleSection = (sectionName: AdditionalSectionKey) => {
  additionalSections.value[sectionName] = !additionalSections.value[sectionName];
};

const ADDITIONAL_KEYS: AdditionalSectionKey[] = [
  "languages",
  "certifications",
  "honors",
  "projects",
  "hobbies",
  "publications",
  "references",
  "voluntaries",
  "exhibitions",
];

watch(
  () => store.state.activeSection,
  (newActiveSection) => {
    if (!newActiveSection) {return;}

    const { sectionName } = newActiveSection;

    if (ADDITIONAL_KEYS.includes(sectionName as AdditionalSectionKey)) {
      ADDITIONAL_KEYS.forEach((key) => {
        additionalSections.value[key] = false;
      });

      additionalSections.value[sectionName as AdditionalSectionKey] = true;
    }
  },
  { immediate: true },
);

</script>

<template>
  <div class="additional-details">

    <div class="section-score">
      <div>
        <h2>{{ t("cvCreationView.AdditionalCvSections") }}</h2>
        <p>{{ t("cvCreationView.AddSection") }}</p>
      </div>
      <CvSectionScore v-if="scoreDetails && evaluated" :scoreDetails="scoreDetails"/>
    </div>
    <div class="button-container">
      <button
        v-for="section in sections"
        :key="section.name"
        :style="{ backgroundColor: getBackgroundColor(section.name) }"
        @click="toggleSection(section.name)"
      >
        {{ t(section.label) }} <font-awesome-icon :icon="additionalSections[section.name] ? 'minus' : 'plus'" />
      </button>
    </div>
    <div>
      <CertificationDetails
        v-if="additionalSections.certifications"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <ProjectDetails
        v-if="additionalSections.projects"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <LanguageDetails
        v-if="additionalSections.languages"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <HonorDetails
        v-if="additionalSections.honors"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <ReferenceDetails
        v-if="additionalSections.references"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <PublicationDetails
        v-if="additionalSections.publications"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <HobbyDetails
        v-if="additionalSections.hobbies"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <ExhibitionDetails
        v-if="additionalSections.exhibitions"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
      <VoluntaryDetails
        v-if="additionalSections.voluntaries"
        :cvId="props.cvId"
        :evaluated="props.evaluated"
        @update:currentStep="$emit('update:currentStep', 'Additional')"
      />
    </div>
  </div>
</template>

<style scoped>

.section-score{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.button-container {
  text-align: left;
  margin-top: 15px;
}

.button-container button {
  color: #020202;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-container button:hover {
    background-color: #d3d0ff !important;;
}

.button-container button:focus {
  outline: none;
}

.additional-details {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.additional-details h2{
  text-align: start;

}

.additional-details p {
  text-align: start;
  color: #7d7d7d;
}
</style>