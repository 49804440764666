const formatShowTwoDecimals = (score: number) => {
  return (Math.round(score * 100) / 100).toFixed(1);
};

export const getActualScore = (score: number | undefined, sectionName: string | undefined, locale: string) => {

  if(score === undefined || !sectionName) {return null;}

  if(locale === "en"){
    switch (sectionName) {
    case "Personal Information":
      return formatShowTwoDecimals(score * 10);
    case "Education":
      return formatShowTwoDecimals(score * 6.666667);

    case "Experience":
      return formatShowTwoDecimals(score * 2.85714);

    case "Skills":
      return formatShowTwoDecimals(score * 4);

    case "Additional Information":
      return formatShowTwoDecimals(score * 6.666667);

    default:
      return formatShowTwoDecimals(score);
    }
  }
  if(locale === "de"){
    switch (sectionName) {
    case "Persönliche Informationen":

      return formatShowTwoDecimals(score * 10);
    case "Ausbildung":
      return formatShowTwoDecimals(score * 6.666667);

    case "Berufserfahrung":
      return formatShowTwoDecimals(score * 2.85714);

    case "Fähigkeiten":
      return formatShowTwoDecimals(score * 4);

    case "Zusätzliche Informationen":
      return formatShowTwoDecimals(score * 6.666667);

    default:
      return formatShowTwoDecimals(score);
    }
  }
  if(locale === "tr"){
    switch (sectionName) {
    case "Kişisel Bilgiler":
      return formatShowTwoDecimals(score * 10);
    case "Eğitim":
      return formatShowTwoDecimals(score * 6.666667);

    case "Deneyim":
      return formatShowTwoDecimals(score * 2.85714);

    case "Beceriler":
      return formatShowTwoDecimals(score * 4);

    case "Ek Bilgiler":
      return formatShowTwoDecimals(score * 6.666667);

    default:
      return formatShowTwoDecimals(score);
    }
  }
  return formatShowTwoDecimals(score);
};