<script setup lang="ts">
import { ref, computed, watch as vueWatch } from "vue";

import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import EvaluationResults from "@/components/EvaluationResults.vue";
import { getComponentFeedbackWithScores, getBasicEvaluation } from "@/services/evaluateService";
import { processFile } from "@/utils/file";
import {
  DEFAULT_SCORE,
  DEFAULT_EVALUATION_TEXT,
  JOB_TITLES_EN,
  JOB_TITLES_DE,
  JOB_TITLES_TR,
} from "@/views/cv-evaluation-view/const";

import EvaluationButton from "./EvaluationButton.vue";
import JobTitleSelector from "./JobTitleSelector.vue";

const { user } = useAuth();
const { t, locale } = useI18n();

const invalidFileError = t("InvalidFileType");
const fileTooLargeError = t("FileTooLarge");

const rightPanelRef = ref<HTMLElement | null>(null);
const modalAuthenticationVisible = ref(false);
const showLoading = ref(false);
const showDetailedEvaluation = ref(false);
const showBasicEvaluation = ref(false);
const basicEvaluationResult = ref({});
const selectedJobTitle = ref("");
const showResults = ref(true);
const isEvaluating = ref(false);
const evaluationText = ref(DEFAULT_EVALUATION_TEXT);
const sectionScores = ref([]);
const creditNotEnough = ref(false);
const score = ref(DEFAULT_SCORE);
const fetchWaiting = ref(false);
const pdfBlobUrl = ref("");
const pdfFile = ref<File | null>(null);
const pdfData = ref("");
const showDetailedEvaluationPopUp = ref(false);

defineEmits(["scrollToTarget"]);

const jobTitles = computed(() => {
  switch (locale.value) {
  case "de":
    return JOB_TITLES_DE;
  case "tr":
    return JOB_TITLES_TR;
  default:
    return JOB_TITLES_EN;
  }
});

const evaluationButtonText = computed(() => {
  if (showDetailedEvaluation.value) {return t("EvaluateAnother");}
  if (showBasicEvaluation.value) {return t("EvaluateFurther");}
  return t("UploadAndEvaluate");
});

const scrollToRightPanel = () => {
  if (rightPanelRef.value) {
    rightPanelRef.value.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

const fetchFeedback = async (text: string, basicEvaluationResult: any) => {
  try {
    const { result: data } = await getComponentFeedbackWithScores(
      text,
      locale.value || "en",
      selectedJobTitle.value,
      user.value?.details?.id || "",
      basicEvaluationResult.value,
    );

    if (data && data.evaluation) {
      // Directly assign the evaluation object (no need to parse)
      basicEvaluationResult.value = data.evaluation;
      showDetailedEvaluation.value = true;
    } else {
      console.warn("No evaluation data found in the response.");
      showDetailedEvaluationPopUp.value = true;
    }

  } catch (error: any) {
    showDetailedEvaluationPopUp.value = true;
  }
};

const fetchBasicFeedback = async (text: string) => {
  try {
    const { result: data } = await getBasicEvaluation(
      text,
      locale.value,
      selectedJobTitle.value,
    );

    if (data) {
      basicEvaluationResult.value = data;
      if(user.value?.authUser){
        await fetchFeedback(text, basicEvaluationResult);
      }
      showBasicEvaluation.value = true;
      fetchWaiting.value = true;
    }
  } catch (error: any) {
    showDetailedEvaluationPopUp.value = true;
  }
};

const openFileSelector = () => {
  document.getElementById("pdfInput")?.click();
};

const doDetailedEvaluation = async () => {
  try {
    if (pdfData.value) {
      await fetchFeedback(pdfData.value, basicEvaluationResult);
    } else {
      alert("No data available for detailed evaluation. Please upload a CV first.");
    }
  } catch (error: any) {
    showDetailedEvaluationPopUp.value = true;
  }
};

const startEvaluation = async () => {
  if (pdfFile.value && !isEvaluating.value) {
    isEvaluating.value = true;
    try {
      showLoading.value = true;
      const text: string = await processFile(
        pdfFile.value,
        invalidFileError,
        fileTooLargeError,
      );
      pdfData.value = text;
      scrollToRightPanel();
      await fetchBasicFeedback(pdfData.value);
    } catch (error) {
      console.error("Evaluation failed:", error);
    }
  } else {
    alert("Please upload a CV first.");
  }
};

const handleEvaluationButtonClick = () => {
  showLoading.value = true;
  if (showDetailedEvaluation.value) {
    openFileSelector();
  } else if (showBasicEvaluation.value && !showDetailedEvaluation.value) {
    if (!user.value?.authUser) {
      modalAuthenticationVisible.value = true;
      fetchWaiting.value = true;
    } else {
      doDetailedEvaluation();
    }
  } else {
    openFileSelector();
  }
  showLoading.value = false;
};

const resetFileInput = () => {
  const fileInput = document.getElementById("pdfInput") as HTMLInputElement;
  if (fileInput) {
    fileInput.value = "";
  }
};

const resetEvaluation = () => {
  pdfFile.value = null;
  pdfBlobUrl.value = "";
  pdfData.value = "";
  score.value = DEFAULT_SCORE;
  evaluationText.value = DEFAULT_EVALUATION_TEXT;
  sectionScores.value = [];
  showDetailedEvaluation.value = false;
  creditNotEnough.value = false;
  basicEvaluationResult.value = {};
};

const handleFileChange = async (event: Event) => {
  showLoading.value = true;
  const file = (event.target as HTMLInputElement).files?.[0];
  if (file && file.type === "application/pdf") {
    resetEvaluation();
    pdfFile.value = file;
    pdfBlobUrl.value = URL.createObjectURL(file);
    if (user.value?.authUser) {
      await startEvaluation();
      await doDetailedEvaluation();
      fetchWaiting.value = false;
      resetFileInput();
    } else {
      startEvaluation();
      fetchWaiting.value = false;
      resetFileInput();

    }

  } else {
    alert("Please select a valid PDF file.");
  }
  showLoading.value = false;
};

const selectJobTitle = (jobTitle: string) => {
  selectedJobTitle.value = jobTitle;
  showResults.value = false;
};

const deselectJobTitle = () => {
  selectedJobTitle.value = "";
  showResults.value = true;
};

vueWatch(
  () => user.value.details,
  (newAuthUser) => {
    if (newAuthUser?.id && fetchWaiting.value ) {
      doDetailedEvaluation();
      resetFileInput();
      fetchWaiting.value = false;
    }
  },
  { deep: true },
);
</script>

<template>
  <AuthModal :isVisible="modalAuthenticationVisible" @update:visible="modalAuthenticationVisible = $event" />

  <div class="evaluation-div">
    <span class="text-evaluate">{{ t("MobileEvaluateResume") }}</span>

    <div ref="rightPanelRef" class="right-panel-mobile">
      <EvaluationResults
        :showLoading="showLoading"
        :showDetailedEvaluation="showDetailedEvaluation"
        :showBasicEvaluation="showBasicEvaluation"
        :selectedJobTitle="selectedJobTitle"
        :basicEvaluationResult="basicEvaluationResult"
        :score="score"
        :evaluationText="evaluationText"
        :sectionScores="sectionScores"
        :pdfData="pdfData"
      />
    </div>

    <input id="pdfInput" type="file" accept=".pdf" style="display: none" @change="handleFileChange" />

    <JobTitleSelector
      v-if="!showDetailedEvaluation && !showBasicEvaluation"
      v-model="selectedJobTitle"
      :t="t"
      :jobTitles="jobTitles"
      :showResults="showResults"
      @selectJobTitle="selectJobTitle"
      @deselectJobTitle="deselectJobTitle"
    />

    <EvaluationButton
      v-if="!showDetailedEvaluation && !showBasicEvaluation"
      :buttonText="evaluationButtonText"
      @click="handleEvaluationButtonClick"
    />
  </div>
</template>

<style>
.evaluation-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 50px;
    padding: 10px;
}
.text-evaluate {
    color: #5438da;
    font-size: 28px;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 42px;
    text-align: center;
    margin-bottom: 30px;
}

.right-panel-mobile {
    position: relative;
    width: 90%;
    flex-direction: column;
    overflow-y: auto;
}
.evaluation-desktop-popup {
  position: absolute;
  top: 0;
  width: 100%;
  height: 300px;
  background-color: #a293ff;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.close-popup-btn {
  position: absolute;
  top: 0px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  z-index: 1001;
}

.close-popup-btn:hover {
  color: #ffcccc;
}
</style>
