<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

// eslint-disable-next-line vue/no-setup-props-destructure
const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();
const  hoveredItemId = ref<string | null>(null);

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {

  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 700, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1500, end: rectForFirst.y + 1640 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

watch(() => cvData, () => {
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {adjustLayout(forbiddenZones.value, divManipulatorRef);}

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    if(!adjust)
    {adjustLayout(forbiddenZones.value, divManipulatorRef);}
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    if(!adjust)
    {adjustLayout(forbiddenZones.value, divManipulatorRef);}
  });
});
</script>

<template>
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="resume-div">
        <div class="personal-info-div">
          <div class="personal-info">
            <span class="first-last-text" :style="{color: cvData.resumeColor}">{{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}<span v-if="cvData.contact?.firstName && cvData.contact?.lastName">,&nbsp;</span>  {{ cvData.contact?.jobTitle }}</span>
            <span class="address-text">{{ cvData.contact?.address }}<span v-if="cvData.contact?.address">,&nbsp;</span> {{ cvData.contact?.email }}<span v-if="cvData.contact?.email">,&nbsp;</span> {{ cvData.contact?.phone }}</span>
          </div>
        </div>
        <div style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.contact" class="profile-desc-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Profile') }}</div>
          <div class="profile-desc-text" v-html="cvData.contact?.description"></div>
        </div>
        <div v-if="cvData.experiences && cvData.experiences.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('EmploymentHistory') }}</div>
          <div v-for="experience in cvData.experiences" :key="experience.id" class="work-experience">
            <div class="date-div">
              <div class="date-text">{{ experience.startDate }}</div>
              <div v-if="experience.endDate" class="date-text">-</div>
              <div class="date-text">{{ experience.endDate }}</div>
            </div>
            <div class="job-div">
              <div class="job-title-company-div">
                <div class="job-title-company-text">{{ experience.title }}</div>
                <div v-if="experience.title" class="job-title-company-text">,&nbsp;</div>
                <div class="job-title-company-text">{{ experience.company }}</div>
              </div>
              <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="job-description" v-html="experience.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.educations && cvData.educations.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.educations && cvData.educations.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Education') }}</div>
          <div v-for="education in cvData.educations" :key="education.id" class="work-experience">
            <div class="date-div">
              <div class="date-text">{{ education.startDate }}</div>
              <div v-if="education.endDate" class="date-text">-</div>
              <div class="date-text">{{ education.endDate }}</div>
            </div>
            <div class="job-div">
              <div class="job-title-company-div">
                <div class="job-title-company-text">{{ education.school }}</div>
              </div>
              <div v-if="education.description && containsTextOutsideHtml(education.description)" class="job-description" v-html="education.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.projects && cvData.projects.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.projects && cvData.projects.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Project') }}</div>
          <div v-for="project in cvData.projects" :key="project.id" class="work-experience">
            <div class="date-div">
              <div class="date-text">{{ project.startDate }}</div>
              <div v-if="project.endDate" class="date-text">-</div>
              <div class="date-text">{{ project.endDate }}</div>
            </div>
            <div class="job-div">
              <div class="job-title-company-div">
                <div class="job-title-company-text">{{ project.title }}</div>
              </div>
              <div v-if="project.description && containsTextOutsideHtml(project.description)" class="job-description" v-html="project.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.skills && cvData.skills.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.skills && cvData.skills.length > 0" class="skill-section-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Skill') }}</div>
          <div class="skill-list-div">
            <div v-for="skill in cvData.skills" :key="skill.id" class="skill-div">
              <div class="skill-name">{{ skill.name }}</div>
              <div v-if="cvData.showSkillLevels && skill.level" class="skill-level">{{ t(skill.level) }}</div>
            </div>
          </div>
        </div>
        <div  v-if="cvData.languages && cvData.languages.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.languages && cvData.languages.length > 0" class="skill-section-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Language') }}</div>
          <div class="skill-list-div">
            <div v-for="language in cvData.languages" :key="language.id" class="skill-div">
              <div class="skill-name">{{ language.name }}</div>
              <div v-if="language.level && cvData.showLanguageLevels" class="skill-level">{{ t(language.level) }}</div>
            </div>
          </div>
        </div>
        <div v-if="cvData.certifications && cvData.certifications.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.certifications && cvData.certifications.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Certification') }}</div>
          <div v-for="certification in cvData.certifications" :key="certification.id" class="work-experience">
            <div class="date-div">
              <div class="date-text">{{ certification.startDate }}</div>
              <div v-if="certification.endDate" class="date-text">-</div>
              <div class="date-text">{{ certification.endDate }}</div>
            </div>
            <div class="job-div">
              <div class="job-title-company-div">
                <div class="job-title-company-text">{{ certification.institute }}</div>
              </div>
              <div class="job-description" v-html="certification.course"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.honors && cvData.honors.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.honors && cvData.honors.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Honor') }}</div>
          <div v-for="honor in cvData.honors" :key="honor.id" class="work-experience">
            <div class="job-div">
              <div class="job-description" v-html="honor.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.references && cvData.references.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.references && cvData.references.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Reference') }}</div>
          <div v-for="reference in cvData.references" :key="reference.id" class="work-experience">
            <div class="date-div">
              <div class="date-text">{{ reference.name }}</div>
              <div class="date-text">{{ reference.company }}</div>
            </div>
            <div class="job-div">
              <div class="job-title-company-div">
                <div class="job-title-company-text">{{ reference.company }}</div>
              </div>
              <div class="job-description" v-html="reference.phone"></div>
              <div class="job-description" v-html="reference.email"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Voluntary') }}</div>
          <div v-for="voluntary in cvData.voluntaries" :key="voluntary.id" class="work-experience">
            <div class="job-div">
              <div class="job-description" v-html="voluntary.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Hobby') }}</div>
          <div v-for="hobby in cvData.hobbies" :key="hobby.id" class="work-experience">
            <div class="job-div">
              <div class="job-description" v-html="hobby.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.publications && cvData.publications.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.publications && cvData.publications.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Publication') }}</div>
          <div v-for="publication in cvData.publications" :key="publication.id" class="work-experience">
            <div class="job-div">
              <div class="job-description" v-html="publication.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" style="width: 515px; height: 1px; opacity: 0.05; border: 1px #212121 solid; margin-top: 20px;"></div>
        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="work-experience-div">
          <div class="section-text" :style="{color: cvData.resumeColor}">{{ t('Exhibition') }}</div>
          <div v-for="exhibition in cvData.exhibitions" :key="exhibition.id" class="work-experience">
            <div class="job-div">
              <div class="job-description" v-html="exhibition.description"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.section-hoverable {
  transition: border 0.2s;
}

.section-hoverable.highlighted {
  border: 1px solid blue;
  background-color: rgb(177, 223, 223);
  cursor: pointer !important;
}

.skill-name{
  color: #212121;
   font-size: calc(var(--global-font-size) + 2px);
   font-weight: 500;
   line-height: 18px;
   word-wrap: break-word;
   text-align: left;
   width: 50%;

}

.skill-level{
  opacity: 0.50;
   color: #212121;
   font-size: var(--global-font-size);
     font-weight: 400;
      line-height: 18px;
       word-wrap: break-word;
       width: 50%;
}

.skill-list-div{
  display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

}

.skill-div{
display: flex;
gap: 20px;
width: 200px;
align-items: flex-start;
justify-content: flex-start;
}

.skill-section-div{
  display: flex;
  margin: 25px;
  gap: 45px;
}

.job-description{
  display: flex;
  width: 310px;
 opacity: 0.60;
  color: #212121;
   font-size: calc(var(--global-font-size) + 1px);
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;

}

.job-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.date-text{
  opacity: 0.50;
   color: #212121;
    font-size: var(--global-font-size);
      font-weight: 400;
       line-height: 18px;
        word-wrap: break-word
}

.work-experience{
  display: flex;
  gap: 75px;
}

.date-div{
  display: flex;
  width: 100px;
}

.job-title-company-div{
  display: flex;
}

.job-title-company-text{
  color: #212121;
  font-size: calc(var(--global-font-size) + 2px);
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word
}

.work-experience-div{
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 25px;
  gap: 20px;

}

.profile-desc-div{
  width: 515px;
  min-height: 88px;
  display: flex;
  gap: 86px;
  padding: 20px;
}

.section-text{
  color: #212121;
   font-size: calc(var(--global-font-size) + 3px);
     font-weight: 600;
      line-height: 15px;
       word-wrap: break-word;
}

.profile-desc-text{
  width: 385px;
   opacity: 0.60;
    color: #212121;
     font-size: calc(var(--global-font-size) + 1px);
       font-weight: 400;
        line-height: 18px;
         word-wrap: break-word
}

.resume-div{
  flex-direction: column;
  width: 595px;
  min-height: 842px;
  background: white;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
}
.personal-info-div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.personal-info{
  display: flex;
  flex-direction: column;
  gap: 5px;

}
.first-last-text{
  text-align: center;
   color: #212121;
    font-size: calc(var(--global-font-size) + 8px);
      font-weight: 600;
      line-height: 25px;
      word-wrap: break-word
}
.address-text{
  opacity: 0.60;
  text-align: center;
   color: #212121;
    font-size: calc(var(--global-font-size) + 1px);
      font-weight: 400;
       line-height: 18px;
        word-wrap: break-word
}

</style>
