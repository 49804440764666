<script setup lang="ts">
import type { PropType } from "vue";
import { ref } from "vue";

import MobileEvaluation from "../evaluation/MobileEvaluation.vue";

const props = defineProps({
  images: {
    type: Array as PropType<string[]>,
    required: true,
  },
  stepNumber: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  showSlides: {
    type: Boolean,
    required: true,
  },
  showEvaluation: {
    type: Boolean,
    required: false,
  },
});

const currentImageIndex = ref(1);
const slideWidth = 100;
const isTransitioning = ref(true);

let startX = 0;

const startSwipe = (e: TouchEvent) => {
  startX = e.touches[0].clientX;
};

const endSwipe = (e: TouchEvent) => {
  const endX = e.changedTouches[0].clientX;
  const distance = endX - startX;

  if (distance > 50) {
    currentImageIndex.value--;
  } else if (distance < -50) {
    currentImageIndex.value++;
  }
};

const handleTransitionEnd = () => {
  if (currentImageIndex.value === 0) {
    isTransitioning.value = false;
    currentImageIndex.value = props.images.length - 1;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        isTransitioning.value = true;
      });
    });
  } else if (currentImageIndex.value === props.images.length + 1) {
    isTransitioning.value = false;
    currentImageIndex.value = 1;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        isTransitioning.value = true;
      });
    });
  }
};

const goToImage = (index: number) => {
  currentImageIndex.value = index;
};

const emit = defineEmits(["scrollToTarget"]);

const emitScrollToTarget = () => {
  emit("scrollToTarget");
};
</script>

<template>
  <div  class="content-container">
    <div class="step-info">
      <span class="step-number">{{ stepNumber }}</span>
      <span class="step-title">{{ title }}</span>
    </div>

    <p class="step-description">{{ description }}</p>

    <div v-if="showSlides" class="carousel-container">
      <div
        class="carousel-track"
        :style="{ transform: `translateX(-${currentImageIndex * slideWidth}%)`, transition: isTransitioning ? 'transform 0.3s ease-in-out' : 'none' }"
        @transitionend="handleTransitionEnd"
        @touchstart="startSwipe"
        @touchend="endSwipe"
      >
        <div class="carousel-slide">
          <img :src="images[images.length - 1]" alt="Last duplicate" class="slide-image" />
        </div>

        <div
          v-for="(image, index) in images"
          :key="index"
          class="carousel-slide"
        >
          <img :src="image" :alt="`Slide ${index + 1}`" class="slide-image"  :class="{ active: index === currentImageIndex - 1 }"/>
        </div>

        <div class="carousel-slide">
          <img :src="images[0]" alt="First duplicate" class="slide-image" />
        </div>
      </div>
      <div class="pagination">
        <span
          v-for="(image, index) in images"
          :key="index"
          :class="{ active: index === currentImageIndex - 1 }"
          class="dot"
          @click="goToImage(index + 1)"
        ></span>
      </div>
    </div>

    <div v-else class="single-image-container">
      <div v-for="(image, index) in images" :key="index" class="image-list-item">
        <img :src="image" :alt="`Image ${index + 1}`" class="image-list-image" />
      </div>
    </div>
  </div>
  <MobileEvaluation v-if="showEvaluation"  @scrollToTarget="emitScrollToTarget"/>
</template>

  <style scoped>

.image-list-item {
    display: flex;
    align-items: center;
    justify-content: center;

}

.image-list-image {
  width: 85%;
  border-radius: 8px;
  margin-bottom: 40px;
}

  .step-info {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    gap: 10px;
  }

  .content-container {
    text-align: center;
    padding-bottom: 20px;
    margin-top: 30px;
  }

  .step-description {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1.5rem;
    margin-left: 40px;
    margin-right: 40px;
    text-align: start;
  }

  .step-title {
    margin-top: 12px;
    font-size: 1.2rem;
    color: #5856d6;
    font-weight: 500;
  }

  .step-number {
    font-size: 4rem;
    color: #5856d6;
    font-weight: 500;
  }

  .carousel-container {
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    padding-top: 35px;
  }

  .carousel-track {
    margin-left: 16%;
    display: flex;
    transition: transform 0.3s ease-in-out;
    width: 70%;
  }

  .carousel-slide {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-image {
    width: 85%;
    height: 350px;
    border: 2px solid #5438da;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .dot.active {
    background-color: #5856d6;
  }

  .slide-image.active {
    transform: translate(0, -8%);
  }

  .single-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .single-slide-image {
    width: 80%;
    height: auto;
    border-radius: 8px;
  }
  </style>
