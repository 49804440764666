<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();
const  hoveredItemId = ref<string | null>(null);

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 740, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1590, end: rectForFirst.y + 1680 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height196vh = window.innerHeight * 1.96;
    const height392vh = window.innerHeight * 3.92;
    const height588vh = window.innerHeight * 5.88;
    const height784vh = window.innerHeight * 7.84;

    if (contentHeight <= height196vh) {
      leftSection.style.minHeight = "196vh";
    } else if (contentHeight > height196vh && contentHeight <= height392vh) {
      leftSection.style.minHeight = "392vh";
    } else if (contentHeight > height392vh && contentHeight <= height588vh) {
      leftSection.style.minHeight = "588vh";
    } else if (contentHeight > height588vh && contentHeight <= height784vh) {
      leftSection.style.minHeight = "784vh";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};
const isColorDark = (color: string) => {
  let r, g, b;
  if (color.startsWith("#")) {
    const hex = color.slice(1);
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    const rgb = color.match(/\d+/g);
    [r, g, b] = rgb ? rgb.map(Number) : [0, 0, 0];
  }
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
};

const textColor = ref("");

watch(() => cvData.resumeColor, (newColor) => {
  textColor.value = isColorDark(newColor) ? "white" : "black";
}, { immediate: true });

watch(() => cvData, () => {

  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="center-container">
        <div v-if="cvData.contact?.firstName || cvData.contact?.lastName" class="person-name"
             :style="{ color: cvData.resumeColor }">
          {{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}
        </div>
        <div v-if="cvData.contact?.jobTitle" class="job-title" :style="{ color: cvData.resumeColor }">{{ cvData.contact?.jobTitle }}</div>

        <div
          class="personal-details section-hoverable"
          :class="{ highlighted: hoveredItemId === `contact-information` }"
          :style="{ border: `1px solid ${cvData.resumeColor}` }"
          @mouseover="hoveredItemId = `contact-information`"
          @mouseleave="hoveredItemId = null"
          @click="$emit('selectSectionItem', { section: 'personal', itemId: null })">
          <div v-if="cvData.contact?.phone" class="personal-details-detail">{{ cvData.contact?.phone }}</div>
          <div v-if="cvData.contact?.email" class="personal-details-detail">{{ cvData.contact?.email }}</div>
          <div v-if="cvData.contact?.city" class="personal-details-detail">{{ cvData.contact?.city }}</div>
          <div v-if="cvData.contact?.country" class="personal-details-detail">{{ cvData.contact?.country }}</div>
          <div v-if="cvData.contact?.address" class="personal-details-detail">{{ cvData.contact?.address }}</div>
          <div v-if="cvData.contact?.birthDate" class="personal-details-detail">{{ cvData.contact?.birthDate }}</div>
        </div>

        <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact.description)" class="section-name" :style="{ color: cvData.resumeColor }">{{ t('Summary') }}</div>
        <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact.description)"
             class="summary-handler section-hoverable"
             :class="{ highlighted: hoveredItemId === `contact-summary` }"
             @mouseover="hoveredItemId = `contact-summary`"
             @mouseleave="hoveredItemId = null"
             @click="$emit('selectSectionItem', { section: 'personal', itemId: null })"
             v-html="cvData.contact.description">
        </div>

        <div v-if="cvData.skills && cvData.skills.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{ t('Skills') }}</div>
        <div v-if="cvData.skills && cvData.skills.length > 0" class="section-content-rows" :style="{ backgroundColor: cvData.resumeColor, color: textColor }">
          <div v-for="(skill, index) in cvData.skills?.length ? cvData.skills : []" :key="skill.id"
               class="section-hoverable"
               :class="{ highlighted: hoveredItemId === `skills-${skill.id}` }"
               @mouseover="hoveredItemId = `skills-${skill.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'skills', itemId: skill.id })"
          >
            <span class="skill" style="font-weight: bold;">{{ skill.name }}</span>
            <span v-if="skill.level && cvData.showSkillLevels" class="skill">&nbsp;-&nbsp;{{ t(skill.level) }}</span>
            <span v-if="index < (cvData.skills?.length || 0) - 1" class="skill"> &nbsp;• </span>
          </div>
        </div>

        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Experiences') }}</div>
        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="section-content-rows-no-background">
          <div v-for="experience in cvData.experiences" :key="experience.id"
               class="experience section-hoverable"
               :class="{ highlighted: hoveredItemId === `experiences-${experience.id}` }"
               @mouseover="hoveredItemId = `experiences-${experience.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'experiences', itemId: experience.id })"
          >
            <div class="organization-div-side">
              <div class="organization-text">
                <div v-if="experience.title" class="education-container">
                  <span class="degree-text">{{ experience.title }}</span>
                  <span class="date-text">
                    <template v-if="experience.startDate">{{ experience.startDate }}</template>
                    <template v-if="experience.startDate && experience.endDate">&nbsp;-&nbsp;</template>
                    <template v-if="experience.endDate">{{ experience.endDate }}</template>
                  </span>

                </div>
              </div>
            </div>

            <div class="inline-container">
              <div class="education-container">
                <span class="school-text">{{ experience.company }}</span>
                <span class="location-text">
                  <template v-if="experience.location">{{ experience.location }}</template>
                </span>
              </div>
            </div>

            <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="description-handler">
              <div class="description-text" v-html="experience.description"></div>
            </div>
          </div>

        </div>

        <div v-if="cvData.educations && cvData.educations.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Educations') }}</div>
        <div v-if="cvData.educations && cvData.educations.length > 0" class="section-content-rows-no-background">
          <div v-for="education in cvData.educations" :key="education.id"
               class="education section-hoverable"
               :class="{ highlighted: hoveredItemId === `educations-${education.id}` }"
               @mouseover="hoveredItemId = `educations-${education.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'educations', itemId: education.id })"
          >
            <div class="organization-div-side">
              <div class="organization-text">
                <div v-if="education.degree" class="education-container">
                  <span class="degree-text">{{ education.degree }}</span>
                  <span class="date-text">
                    <template v-if="education.startDate">{{ education.startDate }}</template>
                    <template v-if="education.startDate && education.endDate">&nbsp;-&nbsp;</template>
                    <template v-if="education.endDate">{{ education.endDate }}</template>
                  </span>
                </div>
              </div>
            </div>

            <div class="inline-container">
              <div class="education-container">
                <span class="school-text">{{ education.school }}</span>
                <span class="location-text">
                  <template v-if="education.location">{{ education.location }}</template>
                </span>
              </div>
            </div>

            <div v-if="education.description && containsTextOutsideHtml(education.description)" class="description-handler">
              <div class="description-text" v-html="education.description"></div>
            </div>
          </div>

        </div>

        <div v-if="cvData.languages && cvData.languages.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Languages') }}</div>
        <div v-if="cvData.languages && cvData.languages.length > 0" class="languages" style="background-color: white; color: black;">
          <div v-for="language,index in cvData.languages" :key="language.id"
               class="sider section-hoverable"
               :class="{ highlighted: hoveredItemId === `languages-${language.id}` }"
               @mouseover="hoveredItemId = `languages-${language.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'languages', itemId: language.id })"
          >
            <div class="language" style="font-weight: 600;">{{ language.name }}</div>
            <div v-if="language.level && cvData.showLanguageLevels" class="language">&nbsp;-&nbsp;</div >
            <div v-if="language.level" class="language">{{ t(language.level) }}</div >
            <span v-if="index < (cvData.languages?.length || 0) - 1" class="language"> &nbsp;• </span>
          </div>
        </div>

        <div v-if="cvData.projects && cvData.projects.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Projects') }}</div>
        <div v-if="cvData.projects && cvData.projects.length > 0" class="section-content-rows-no-background">
          <div v-for="project in cvData.projects" :key="project.id"
               class="projects section-hoverable"
               :class="{ highlighted: hoveredItemId === `projects-${project.id}` }"
               @mouseover="hoveredItemId = `projects-${project.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'projects', itemId: project.id })"
          >
            <div class="dates-title">
              <div class="main-title">{{ project.title }}</div>
              <div v-if="project.startDate" class="sub-title" style="font-weight: bold;">{{ project.startDate }} - {{ project.endDate }}</div>
            </div>
            <div v-if="project.description && containsTextOutsideHtml(project.description)" class="description-handler" v-html="project.description"></div>
          </div>
        </div>

        <div v-if="cvData.certifications && cvData.certifications.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Certifications') }}</div>
        <div v-if="cvData.certifications && cvData.certifications.length > 0" class="section-content-rows-no-background">
          <div v-for="certification in cvData.certifications" :key="certification.id"
               class="certifications section-hoverable"
               :class="{ highlighted: hoveredItemId === `certifications-${certification.id}` }"
               @mouseover="hoveredItemId = `certifications-${certification.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'certifications', itemId: certification.id })"
          >
            <div class="dates-title">
              <div class="main-title">{{ certification.course }}</div>
              <div v-if="certification.startDate" class="sub-title">{{ certification.startDate }} {{ certification.endDate? "-" : "" }} {{ certification.endDate }}</div>
            </div>
            <div class="sub-title">{{ certification.institute }}</div>
          </div>
        </div>

        <div v-if="cvData.references && cvData.references.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('References') }}</div>
        <div v-if="cvData.references && cvData.references.length > 0" class="section-content-rows-no-background">
          <div v-for="reference in cvData.references" :key="reference.id"
               class="references section-hoverable"
               :class="{ highlighted: hoveredItemId === `references-${reference.id}` }"
               @mouseover="hoveredItemId = `references-${reference.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'references', itemId: reference.id })"
          >
            <div v-if="reference.name" class="sub-title" style="font-weight: 600;">{{ reference.name }}</div>
            <div v-if="reference.company" class="sub-title">{{ reference.company }}</div>
            <div v-if="reference.phone"  class="sub-title">{{ reference.phone }}</div>
            <div v-if="reference.email"  class="sub-title">{{ reference.email }}</div>
          </div>
        </div>

        <div v-if="cvData.publications && cvData.publications.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Publications') }}</div>
        <div v-if="cvData.publications && cvData.publications.length > 0" class="section-content-rows-no-background">
          <div v-for="publication in cvData.publications" :key="publication.id"
               class="publications section-hoverable"
               :class="{ highlighted: hoveredItemId === `publications-${publication.id}` }"
               @mouseover="hoveredItemId = `publications-${publication.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'publications', itemId: publication.id })"
          >
            <div v-if="publication.description && containsTextOutsideHtml(publication.description)" class="description-handler" v-html="publication.description"></div>
          </div>
        </div>

        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Hobbies') }}</div>
        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="section-content-rows-no-background">
          <div v-for="hobbie in cvData.hobbies" :key="hobbie.id"
               class="hobbies section-hoverable"
               :class="{ highlighted: hoveredItemId === `hobbies-${hobbie.id}` }"
               @mouseover="hoveredItemId = `hobbies-${hobbie.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'hobbies', itemId: hobbie.id })"
          >
            <div v-if="hobbie.description && containsTextOutsideHtml(hobbie.description)" class="description-handler" v-html="hobbie.description"></div>
          </div>
        </div>

        <div v-if="cvData.honors && cvData.honors.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Honors & Awards') }}</div>
        <div v-if="cvData.honors && cvData.honors.length > 0" class="section-content-rows-no-background">
          <div v-for="honor in cvData.honors" :key="honor.id"
               class="honors section-hoverable"
               :class="{ highlighted: hoveredItemId === `honors-${honor.id}` }"
               @mouseover="hoveredItemId = `honors-${honor.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'honors', itemId: honor.id })"
          >
            <div v-if="honor.description && containsTextOutsideHtml(honor.description)" class="description-handler" v-html="honor.description"></div>
          </div>
        </div>

        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Voluntary Activities') }}</div>
        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="section-content-rows-no-background">
          <div v-for="voluntary in cvData.voluntaries" :key="voluntary.id"
               class="voluntaries section-hoverable"
               :class="{ highlighted: hoveredItemId === `voluntaries-${voluntary.id}` }"
               @mouseover="hoveredItemId = `voluntaries-${voluntary.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'voluntaries', itemId: voluntary.id })"
          >
            <div v-if="voluntary.description && containsTextOutsideHtml(voluntary.description)" class="description-handler" v-html="voluntary.description"></div>
          </div>
        </div>

        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="section-name" :style="{ color: cvData.resumeColor }">{{  t('Exhibitions') }}</div>
        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="section-content-rows-no-background">
          <div v-for="exhibition in cvData.exhibitions" :key="exhibition.id"
               class="exhibitions section-hoverable"
               :class="{ highlighted: hoveredItemId === `exhibitions-${exhibition.id}` }"
               @mouseover="hoveredItemId = `exhibitions-${exhibition.id}`"
               @mouseleave="hoveredItemId = null"
               @click="$emit('selectSectionItem', { section: 'exhibitions', itemId: exhibition.id })"
          >
            <div v-if="exhibition.description && containsTextOutsideHtml(exhibition.description)" class="description-handler" v-html="exhibition.description"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.section-hoverable {
  transition: border 0.2s;
}

.section-hoverable.highlighted {
  border: 1px solid blue;
  background-color: rgb(177, 223, 223);
  cursor: pointer !important;
}

.inline-container {
  width: 100%;
}

.education-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

.degree-text {
  width: 65%;
  font-weight: 600 !important;
  word-wrap: break-word;
  white-space: normal;
}

.location-text {
  width: 35%;
  text-align: right;
  font-size: calc(var(--global-font-size) + 2px);
  font-weight: 450 !important;
  white-space: nowrap;
  align-self: flex-start;
}

.school-text {
  width: 65%;
  font-weight: 450;
  font-size: calc(var(--global-font-size) + 2px);
  word-wrap: break-word;
  white-space: normal;
}

.date-text {
  width: 35%;
  text-align: right;
  font-weight: 600 !important;
  white-space: nowrap;
  vertical-align: top;
}

.organization-div-side {
  width: 100%;
}

.description-handler {
  margin-top: 6px;
}

.organization-text, .period-text {
  font-size: calc(var(--global-font-size) + 2px);
  color: #333333;
  font-weight: 600;
}

.description-text {
  font-size: calc(var(--global-font-size) - 0px);
  font-weight: 400;
  word-wrap: break-word;
  white-space: normal;
}

.resume {
  width: 595px;
  max-width: 100%;
  max-height: 2524px;
  background: var(--white);
  display: flex;
  text-align: start !important;
  min-height: 700px;
}
.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}
.person-name {
  font-weight: 700;
  font-size: calc(var(--global-font-size) + 16px);
  font-style: normal;
  color: black;
  font-kerning: none;
  text-decoration: none;
  align-self: center;
  text-align: center;
}
.job-title {
  font-size: calc(var(--global-font-size) + 10px);
  font-weight: 400;
  font-style: normal;
  color: black;
  font-kerning: none;
  text-decoration: none;
}
.personal-details {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  row-gap: 5px;
  max-width: 575px;
  color: black;;
  border: 1px solid black;
  font-size: calc(var(--global-font-size) + 4px);
  font-weight: 700;
  flex-wrap: wrap;
  width: 100%;
}
.personal-details > *:not(:last-child)::after {
  content: "|";
  margin-left: 5px;
}

.personal-details-detail {
  padding: 0 2px;
}

.personal-details-separator {
  margin: 0 2px;
}

.section-name{
  padding-top: 8px;
  align-self: flex-start;
  padding-bottom: 2px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 5px;
  font-size: calc(var(--global-font-size) + 4px);
  color: black;
  width: 100%;
  font-weight: 700;
}
.section-content-rows {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  padding: 5px 0;
  align-self: flex-start;
  width: 100%;
  background-color: black;
  color: white;
}
.languages {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: flex-start;
  padding-right: 5px;
  width: 100%;
  color: black;
}
.skill, .language {
  font-weight: 500;
  font-size: calc(var(--global-font-size) + 2px);
}

.language{
  padding-right: 5px;
}
.experience, .education, .projects, .certifications, .hobbies, .honors, .voluntaries, .publications, .exhibitions {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  position: relative;
  width: 100%;
}
.experience:last-child, .education:last-child {
  padding-bottom: 0;
}
.references {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 5px;
  align-items: flex-start;
  width: 100%;
}

.main-title {
  font-size: calc(var(--global-font-size) + 2px);
  font-weight: 600;
  font-style: normal;
  color: black;
  font-kerning: none;
  text-decoration: none;
}
.sub-title {
  font-size: calc(var(--global-font-size) + 2px);
  color: black;
  font-kerning: none;
  text-decoration: none;
  padding-bottom: 2px;
}
.section-content-rows-no-background {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}
.dates {
  display: flex;
  justify-content: end;
}
.dates-title{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 2px;
  width: 100%;
}
.description-handler {
  width: 100%;
  font-size: calc(var(--global-font-size) + 0px);
}

.description-handler *{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.summary-handler{
  width: 100%;
  font-size: calc(var(--global-font-size) + 0px);
  column-gap: 2px;
  row-gap: 2px;
}

.summary-handler *{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.hobbies{
  padding-bottom: 5px;
}

.sider{
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}
  </style>

