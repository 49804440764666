import useHttp from "@/composables/useHttp";
import { API_URL } from "@/config";

export const getComponentFeedbackWithScores = async (cvText: string, language:string, jobTitle: string, uid: string, evaluation: any) => {
  const { data, error, loading, execute } = useHttp<{evaluation: string}>(API_URL + "/componentsFeedbackWithScores", {
    method: "POST",
  });

  const result = await execute({ cvText, jobTitle, language, uid, evaluation } );
  return { data, error, loading, result };
};

export const getBasicEvaluation = async (cvText: string, language:string, jobTitle: string) => {
  const { data, error, loading, execute } = useHttp<{evaluation: object}>(API_URL + "/basicEvaluation", {
    method: "POST",
  }, false);

  const result = await execute({ cvText, jobTitle, language } );
  return { data, error, loading, result };
};
