<script setup lang="ts">
import { ref, onMounted, watch, reactive, computed } from "vue";

import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
const { user } = useAuth();
import { getCurrentLocale } from "@/utils/locale";
import CvEvaluationScore from "@/views/cv-evaluation-view/cv-evaluation/cv-evaluation-output-sections/CvEvaluationScore.vue";
import { categoryMessages }  from "@/views/info-view/evaluation/const";

import UpgradePremium from "./UpgradePremium.vue";
const { t } = useI18n();

const props = defineProps<{
  basicEvaluationResult?: Record<string, any>;
    maxWarnings?: number;
}>();

const isDesktop = ref(window.innerWidth >= 768);
onMounted(() => {
  window.addEventListener("resize", () => {
    isDesktop.value = window.innerWidth >= 768;
  });
});

const shouldBlurWarnings = computed(() => {
  return !user.value.authUser || !isDesktop.value;
});

const evaluationResult = reactive(props.basicEvaluationResult || {});
const computedMaxWarnings = computed(() => (user.value?.authUser ? 4 : 2));

const selectedCategory = ref("redFlags");
const selectedCategoryData = ref(evaluationResult?.["redFlags"]);
const selectedCategoryScore = ref(selectedCategoryData.value?.score || 0);
const warnings = ref<{ warningText: string; warning: any }[]>([]);
const modalAuthenticationVisible = ref(false);
const upgradePremiumVisible = ref(true);

const postProcessEvaluation = () => {

  const currentLocale = getCurrentLocale();
  const messages = categoryMessages[currentLocale] || categoryMessages.en;

  if (!selectedCategoryData.value) {
    warnings.value = [];
    return;
  }

  const warningEntries = Object.keys(selectedCategoryData.value)
    .filter((key) => key !== "score")
    .map((key) => ({
      category: key,
      items: [...(selectedCategoryData.value[key] || [])],
    }))
    .filter(({ items }) => items.length > 0);

  const interleavedWarnings: Array<{ warningText: string; warning: string }> = [];
  let added = true;

  while (added) {
    added = false;
    for (const { category, items } of warningEntries) {
      if (items.length > 0) {
        const warning = items.shift();
        interleavedWarnings.push({
          warningText: messages[category] || "Issue detected",
          warning,
        });
        added = true;
      }
    }
  }

  warnings.value = [...interleavedWarnings]; // Force Vue reactivity
};

const selectCategory = (category: string) => {
  if (!user.value.authUser && !["redFlags", "skills"].includes(category)) {
    modalAuthenticationVisible.value = true;
    return;
  }

  if (
    user.value.authUser &&
    user.value.details?.evaluationCredit === 0 &&
    !["redFlags", "skills"].includes(category) &&
    isDesktop.value
  ) {
    upgradePremiumVisible.value = true;
    return;
  }

  selectedCategory.value = category;
  selectedCategoryData.value = evaluationResult?.[category];

  selectedCategoryScore.value = selectedCategoryData.value?.score || 0;

};

watch(selectedCategory, () => {
  selectedCategoryData.value = { ...evaluationResult[selectedCategory.value] };
  selectedCategoryScore.value = selectedCategoryData.value?.score || 0;
  postProcessEvaluation();
});

onMounted(() => {
  postProcessEvaluation();
});

const categories = [
  {
    name: "redFlags",
    icon: {
      path1: "M0 0h24v24H0z",
      path2: "M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6z",
    },
  },
  {
    name: "skills",
    icon: {
      path1: "M0 0h24v24H0z",
      path2: "M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z",
    },
  },
  {
    name: "structure",
    icon: {
      path1: "M0 0h24v24H0z",
      path2: "M23 7V1h-6v2H7V1H1v6h2v10H1v6h6v-2h10v2h6v-6h-2V7h2zM3 3h2v2H3V3zm2 18H3v-2h2v2zm12-2H7v-2H5V7h2V5h10v2h2v10h-2v2zm4 2h-2v-2h2v2zM19 5V3h2v2h-2zm-5.27 9h-3.49l-.73 2H7.89l3.4-9h1.4l3.41 9h-1.63l-.74-2zm-3.04-1.26h2.61L12 8.91l-1.31 3.83z",
    },
  },
  {
    name: "content",
    icon: {
      path1: "M0 0h24v24H0z",
      path2: "M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 9h-7V4h7v7zm-9-7v7H4V4h7zm-7 9h7v7H4v-7zm9 7v-7h7v7h-7z",
    },
  },
  {
    name: "sections",
    icon: {
      path1: "M0 0h24v24H0z",
      path2: "M3 9h14V7H3v2zm0 4h14v-2H3v2zm0 4h14v-2H3v2zm16 0h2v-2h-2v2zm0-10v2h2V7h-2zm0 6h2v-2h-2v2z",
    },
  },
];

const visibleWarnings = computed(() =>
  warnings.value.slice(0, computedMaxWarnings.value),
);

const blurredWarnings = computed(() =>
  warnings.value.slice(computedMaxWarnings.value),
);
const openAuthModal = () => {
  modalAuthenticationVisible.value = true;
};
</script>

<template>
  <AuthModal :isVisible="modalAuthenticationVisible" @update:visible="modalAuthenticationVisible = $event" />
  <div class="basic-evaluation-result">
    <h3>{{ t('YourScore') }}</h3>
    <CvEvaluationScore :score="selectedCategoryScore" :show-text="false" :width="isDesktop ? 120 : 60" />
    <div class="card">
      <div class="icon-card">
        <div
          v-for="category in categories"
          :key="category.name"
          class="category-card"
          :class="{ selected: selectedCategory === category.name,
                    locked:  (user.details?.evaluationCredit === 0 || !user.authUser) && !['redFlags', 'skills'].includes(category.name) }"
        >
          <button
            class="category-button"
            :disabled="!isDesktop && (user.details?.evaluationCredit === 0 || !user.authUser) && !['redFlags', 'skills'].includes(category.name)"
            @click="selectCategory(category.name)"
          >
            <svg class="category-icon" viewBox="0 0 24 24">
              <path :d="category.icon.path1" fill="none"></path>
              <path :d="category.icon.path2"></path>
            </svg>
            <!-- Lock Icon -->
            <div v-if="(!user.authUser || (user.details && user.details.evaluationCredit == 0)) && !['redFlags', 'skills'].includes(category.name)" class="lock-overlay">
              <svg class="lock-icon" viewBox="0 0 24 24">
                <path
                  d="M12 1.5C9.514 1.5 7.5 3.514 7.5 6v3H6C4.896 9 4 9.896 4 11v9c0 1.104.896 2 2 2h12c1.104 0 2-.896 2-2v-9c0-1.104-.896-2-2-2h-1.5V6c0-2.486-2.014-4.5-4.5-4.5zm0 1.5c1.657 0 3 1.343 3 3v3h-6V6c0-1.657 1.343-3 3-3zm0 9c.828 0 1.5.672 1.5 1.5 0 .828-.672 1.5-1.5 1.5-.828 0-1.5-.672-1.5-1.5 0-.828.672-1.5 1.5-1.5zm0 2.5c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                  fill="#999"
                />
              </svg>
            </div>
          </button>
          <p class="category-text">{{ t(category.name) }}</p>
        </div>
      </div>
      <div class="warnings scrollable-container">
        <div v-if="warnings.length === 0" class="success-message">
          <svg class="success-icon" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15l-4-4 1.41-1.41L11 14.17l5.59-5.59L18 10l-7 7z"
            ></path>
          </svg>
          <p v-if="selectedCategory== 'skills' && selectedCategoryScore !== 100" class="success-text">
            {{ t('JobTitleNotSelected') }}
          </p>
          <p v-else class="success-text">
            {{ t("PerfectScore") }}
          </p>
        </div>
        <div v-if="selectedCategory === 'sections'">
          <div
            v-for="(section, index) in selectedCategoryData.sections"
            :key="index"
            class="section-item"
          >
            <div class="section-icon">
              <CvEvaluationScore :score="Number(section.score)" :width="isDesktop ? 60 : 40" :show-text="false"  />
            </div>
            <div class="section-details">
              <div class="section-header">
                <span class="section-name">{{ t(section.sectionName) }}</span>
              </div>
              <p class="section-evaluation">{{ section.evaluation }}</p>
            </div>
          </div>
        </div>

        <!-- Other Categories -->
        <div v-else>
          <!-- Non-blurred warnings -->
          <div
            v-for="warning in visibleWarnings"
            :key="warning.warning"
            class="warning-item"
          >
            <svg class="warning-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
            <div class="warning-adjuster">
              <p class="warning-text">{{ warning.warningText }}</p>
              <p class="warning-detail">{{ warning.warning }}</p>
            </div>
          </div>
          <!-- Unlock more card -->
          <div
            v-if="blurredWarnings.length > 0 && (!user.authUser || !isDesktop)"
            class="unlock-more-card"
          >
            <div class="icon-text-wrapper">
              <svg class="lock-icon-unlock" viewBox="0 0 24 24">
                <path
                  d="M12 1.5C9.514 1.5 7.5 3.514 7.5 6v3H6C4.896 9 4 9.896 4 11v9c0 1.104.896 2 2 2h12c1.104 0 2-.896 2-2v-9c0-1.104-.896-2-2-2h-1.5V6c0-2.486-2.014-4.5-4.5-4.5zm0 1.5c1.657 0 3 1.343 3 3v3h-6V6c0-1.657 1.343-3 3-3zm0 9c.828 0 1.5.672 1.5 1.5 0 .828-.672 1.5-1.5 1.5-.828 0-1.5-.672-1.5-1.5 0-.828.672-1.5 1.5-1.5zm0 2.5c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                  fill="#e0dada;"
                />
              </svg>
              <div class="login-text">
                {{ !user.authUser ? `${blurredWarnings.length} ${t('LoginText')}` : t('NoCreditsGoDesktop') }}
              </div>
            </div>
            <button v-if="!user.authUser" class="login-button" @click="openAuthModal">{{ t("Login") }}</button>
          </div>

          <!-- Blurred warnings -->
          <div
            v-for="warning in blurredWarnings"
            :key="warning.warning"
            class="warning-item"
            :class="{ blurred: shouldBlurWarnings }"
          >
            <svg class="warning-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
            <div class="warning-adjuster">
              <p class="warning-text">{{ warning.warningText }}</p>
              <p class="warning-detail">{{ warning.warning }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.authUser && !isDesktop" style="width: 100%; border: 1px solid purple"></div>
      <div v-if="user.authUser && !isDesktop" class="text-container">
        <div style="margin-top: 10px; font-weight: 500;">{{ t("EvaluationText1") }}</div>
        <div style="margin-top: 20px;">{{ t("EvaluationText2") }}</div>
        <div style="margin-top: 20px; font-weight: 500;">{{ t("EvaluationText3") }}</div>
        <div style="margin-top: 20px;">{{ t("EvaluationText4") }} </div>
      </div>
      <div v-if="user.authUser && isDesktop && user.details?.accountStatus === 'Free' && upgradePremiumVisible">
        <UpgradePremium :premium-reason-text="t('UpgradePremium.UpgradeToPremiumTextEvaluate')" :show-features="true" @close="upgradePremiumVisible = false" />
      </div>
    </div>

  </div>
</template>

<style scoped>
.card {
  width: 100%;
  background-color: #eaeefa;
  border: solid 1px #eaeefa;
  border-radius: 26px;
  height: auto;
}
.icon-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px 15px;
  display: flex;
}
.category-card {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.category-card.selected {
  background-color: #beb8b8;
}
.category-card.locked {
  opacity: 0.8;
}

.category-button {
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 8px;
  background: transparent;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.category-icon {
  font-size: 45px;
  width: 45px;
  height: 45px;
  color: #030303;
  fill: #030303;
  border-radius: 8px;
  align-self: center;
  margin: 0;
  padding: 0;
  margin: 0;
}
.category-text {
  font-size: 10px;
  color: #030303;
  margin-top: 8px;
  word-wrap: break-word;
  text-align: center;
  padding-top: 3px;
}
.warnings {
  margin-top: 25px;
  align-items: flex-start;
  height: 280px;
  overflow-y: auto;
  padding-right: 10px;
  min-width: 100%;
}

.warning-item {
  display: flex;
  justify-content: flex-start;
  margin-left: 0px;
  padding-left: 0px;
  align-items: center;
  gap: 10px;
}

.warning-icon {
  color: #ff5555;
  fill: #ff5555;
  font-size: 30px;
  width: 35px;
  height: 35px;
  margin-right: 5px;
  flex-shrink: 0;
}
.success-icon {
  fill: #4caf50;
  color: #4caf50;
  font-size: 30px;
  width: 35px;
  height: 35px;
  margin-right: 5px;
  flex-shrink: 0;
}

.warning-text, .success-text {
  font-weight: bold;
  font-size: 10px;
  margin-right: 5px;
  text-align: left;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.success-message {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #e8f5e9;
  border: 1px solid #4caf50;
  border-radius: 5px;
  margin-bottom: 10px;
}
.warning-detail {
  color: #666;
  font-size: 10px;
  text-align: left;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 2px;
  white-space: pre-wrap;
}
.warning-adjuster{
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 5px;
  margin-bottom: 10px;
}
.scrollable-container {
  padding-top: 5px;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}
.locked {
  opacity: 0.2;
}

.blurred {
  filter: blur(3px);
  opacity: 0.6;
}

.lock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(29, 27, 27, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.lock-icon {
  width: 35px;
  height: 35px;
  fill: #e0dada;
}

.lock-icon-unlock {
  width: 35px;
  height: 35px;
  margin-left: 5px;
}
.login-text {
  font-size: 12px;
  color: #ffffff;
  margin-left: 10px;
  margin-top: 5px;
  text-align: start;
}
.section-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  padding: 10px;
  border: #666 2px solid;
  border-radius: 8px;
}

.section-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.section-icon-svg {
  width: 35px;
  height: 35px;
}

.section-details {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.section-header {
  display: flex;
  text-align: left;
  margin-bottom: 5px;
  color: #666;
}

.section-name {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.section-score {
  width: 40px;
  height: 40px;
  position: absolute;
}
.section-evaluation {
  font-size: 12px;
  color: #666;
  margin: 0;
  text-align: left;
  padding-bottom: 5px;
}

.unlock-more-card {
  position: relative;
  height: 120px;
  background-color: #a293ff;
  border-radius: 26px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lock-icon-unlock {
  width: 35px;
  height: 35px;
}

.login-text {
  font-size: 12px;
  color: #ffffff;
}

.login-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #5438da;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #261e59;;
}
.text-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
  border-radius: 26px;
  text-align: start;
}

@media (min-width: 768px) {
  .card {
  width: 90%;
  max-width: 1200px;
  margin-left: 5%;
}
.warnings {
  margin-top: 45px;
  align-items: flex-start;
  height: auto;
  overflow-y: auto;
  padding-right: 10px;
  min-width: 100%;
}
.lock-icon {
  width: 50px;
  height: 50px;
}

.lock-icon-unlock {
  width: 60px;
  height: 60px;
  margin-left: 10px;
}
.section-icon {
  width: 60px;
  height: 60px;
}

.category-icon {
  font-size: 60px;
  width: 80px;
  height: 60px;
}
.category-text {
  font-size: 16px;
  margin-top: 8px;
  padding-top: 8px;
  word-wrap:normal;
}

.warning-icon {
  color: #ff5555;
  fill: #ff5555;
  font-size: 60px;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  flex-shrink: 0;
}
.success-icon {
  fill: #4caf50;
  color: #4caf50;
  font-size: 60px;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  flex-shrink: 0;
}
.section-icon {
  width: 60px;
  height: 60px;
}

.section-icon-svg {
  width: 60px;
  height: 60px;
}
.icon-card {
  gap: 0px 50px;
}
.category-button {
  height: 60px;
  width: 60px;
}
.category-card {
  height: 60px;
  width: 60px;
}
.unlock-more-card {
  position: relative;
  height: 200px;
  width: 90%;
  margin-left: 5%;
}
.warning-detail {
  font-size: 18px;
}
.warning-adjuster{
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 10px;
  margin-bottom: 15px;
}
.warning-text, .success-text {
  font-size: 15px;
  margin-right: 10px;
}
.lock-icon-unlock {
  width: 75px;
  height: 75px;
}

.login-text {
  font-size: 20px;
  color: #ffffff;
}

.login-button {
  min-height: 60px;
  min-width: 100px;
}
.scrollable-container {
  padding-top: 20px;
}
.section-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  padding: 10px;
  border: #666 2px solid;
  border-radius: 8px;
}

.section-icon {
  width: 50px;
  height: 50px;
}

.section-icon-svg {
  width: 50px;
  height: 50px;
}
.section-name {
  font-size: 18px;
}

.section-score {
  width: 60px;
  height: 60px;
}
.section-evaluation {
  font-size: 16px;
  padding-bottom: 10px;
}

}
</style>

