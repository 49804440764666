<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();
const  hoveredItemId = ref<string | null>(null);

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();
    forbiddenZones.value = [
      { start: rectForFirst.y + 740, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1590, end: rectForFirst.y + 1680 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height196vh = window.innerHeight * 1.96;
    const height392vh = window.innerHeight * 3.92;
    const height588vh = window.innerHeight * 5.88;
    const height784vh = window.innerHeight * 7.84;

    if (contentHeight <= height196vh) {
      leftSection.style.minHeight = "196vh";
    } else if (contentHeight > height196vh && contentHeight <= height392vh) {
      leftSection.style.minHeight = "392vh";
    } else if (contentHeight > height392vh && contentHeight <= height588vh) {
      leftSection.style.minHeight = "588vh";
    } else if (contentHeight > height588vh && contentHeight <= height784vh) {
      leftSection.style.minHeight = "784vh";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};

watch(() => cvData, () => {

  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
});

onMounted(async () => {
  await nextTick();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ '--resume-color': cvData.resumeColor, fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="center-container">
        <div class="top-section">
          <div
            class="details-left-side section-hoverable"
            :class="{ highlighted: hoveredItemId === `contact-summary` }"
            @mouseover="hoveredItemId = `contact-summary`"
            @mouseleave="hoveredItemId = null"
            @click="$emit('selectSectionItem', { section: 'personal', itemId: null })"
          >
            <div v-if="cvData.contact?.firstName || cvData.contact?.lastName" class="person-name"
                 :style="{ color: cvData.resumeColor }">
              {{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}
            </div>
            <div v-if="cvData.contact?.jobTitle" class="job-title">{{ cvData.contact?.jobTitle }}</div>
            <div v-if="cvData.contact?.description && containsTextOutsideHtml(cvData.contact?.description)" class="descriptions"
                 v-html="cvData.contact?.description">
            </div>
          </div>
          <div
            class="details-right-side section-hoverable"
            :class="{ highlighted: hoveredItemId === `contact-information` }"
            @mouseover="hoveredItemId = `contact-information`"
            @mouseleave="hoveredItemId = null"
            @click="$emit('selectSectionItem', { section: 'personal', itemId: null })"
          >
            <div v-if="cvData.contact?.phone" class="sub-title">{{ cvData.contact?.phone }}</div>
            <div v-if="cvData.contact?.email" class="sub-title">{{ cvData.contact?.email }}</div>
            <div v-if="cvData.contact?.city" class="sub-title">{{ cvData.contact?.city }}</div>
            <div v-if="cvData.contact?.country" class="sub-title">{{ cvData.contact?.country }}</div>
            <div v-if="cvData.contact?.address" class="sub-title">{{ cvData.contact?.address }}</div>
            <div v-if="cvData.contact?.birthDate" class="sub-title">{{ cvData.contact?.birthDate }}</div>
          </div>
        </div>
        <div class="horizontal-line"></div>
        <div class="bottom-section">
          <div class="main-left-side">
            <div v-if="cvData.educations && cvData.educations.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Education') }}</div>
              <div v-for="(education, index) in cvData.educations" :key="education.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `educations-${education.id}` }"
                   @mouseover="hoveredItemId = `educations-${education.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'educations', itemId: education.id })"
              >
                <div v-if="education.school" class="sub-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ education.school }}</div>
                <div class="vertical-adjuster" style="padding-bottom: 3px; font-weight: 300;">
                  <div v-if="education.location" class="sub-title">{{ education.location }}</div>
                  <div v-if="education.startDate || education.endDate" class="sub-title">
                    <span v-if="education.startDate">{{ education.startDate }}</span>
                    <span v-if="education.startDate && education.endDate">&nbsp;-&nbsp;</span>
                    <span v-if="education.endDate">{{ education.endDate }}</span>
                  </div>
                </div>
                <div v-if="education.description && containsTextOutsideHtml(education.description)" class="sub-title"
                     v-html="education.description">
                </div>
                <div v-if="index !== cvData.educations.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.skills && cvData.skills.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Skills') }}</div>
              <div v-for="(skill, index) in cvData.skills" :key="skill.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `skills-${skill.id}` }"
                   @mouseover="hoveredItemId = `skills-${skill.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'skills', itemId: skill.id })"
              >
                <div class="sub-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ skill.name }}</div>
                <div v-if="skill.level && cvData.showSkillLevels" class="sub-title" style="font-size: calc(var(--global-font-size) + 2px);">{{ t(skill.level) }}</div>
                <div v-if="index !== cvData.skills.length - 1" class="padding-adder"></div>
              </div>
            </div>
            <div class="horizontal-line"></div>
            <div v-if="cvData.languages && cvData.languages.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Languages') }}</div>
              <div v-for="(language, index) in cvData.languages" :key="language.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `languages-${language.id}` }"
                   @mouseover="hoveredItemId = `languages-${language.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'languages', itemId: language.id })"
              >
                <div class="sub-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ language.name }}</div>
                <div v-if="language.level && cvData.showLanguageLevels" class="sub-title" style="font-size: calc(var(--global-font-size) + 2px);">{{ t(language.level) }}</div>
                <div v-if="index !== cvData.languages.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.certifications && cvData.certifications.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Certifications') }}</div>
              <div v-for="(certification, index) in cvData.certifications" :key="certification.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `certifications-${certification.id}` }"
                   @mouseover="hoveredItemId = `certifications-${certification.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'certifications', itemId: certification.id })"
              >
                <div v-if="certification.course" class="sub-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ certification.course }}</div>
                <div v-if="certification.institute" class="sub-title">{{ certification.institute }}</div>
                <div class="vertical-adjuster" style="padding-bottom: 3px; font-weight: 300;">
                  <div v-if="certification.startDate" class="sub-title">{{ certification.startDate }} - {{ certification.endDate }}</div>
                </div>
                <div v-if="index !== cvData.certifications.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.references && cvData.references.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('References') }}</div>
              <div v-for="(reference, index) in cvData.references" :key="reference.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `references-${reference.id}` }"
                   @mouseover="hoveredItemId = `references-${reference.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'references', itemId: reference.id })"
              >
                <div v-if="reference.name" class="sub-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ reference.name }}</div>
                <div v-if="reference.company" class="sub-title">{{ reference.company }}</div>
                <div v-if="reference.email" class="sub-title">{{ reference.email }}</div>
                <div v-if="reference.phone" class="sub-title">{{ reference.phone }}</div>
                <div v-if="index !== cvData.references.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="main-right-side">
            <div v-if="cvData.experiences && cvData.experiences.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Experience') }}</div>
              <div v-for="(experience, index) in cvData.experiences" :key="experience.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `experiences-${experience.id}` }"
                   @mouseover="hoveredItemId = `experiences-${experience.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'experiences', itemId: experience.id })"
              >
                <div v-if="experience.company" class="sub-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ experience.company }}</div>
                <div class="vertical-adjuster" style="padding-bottom: 3px; font-weight: 300;">
                  <div v-if="experience.location" class="sub-title">{{ experience.location }}</div>
                  <div v-if="experience.startDate || experience.endDate" class="sub-title">
                    <span v-if="experience.startDate">{{ experience.startDate }}</span>
                    <span v-if="experience.startDate && experience.endDate">&nbsp;-&nbsp;</span>
                    <span v-if="experience.endDate">{{ experience.endDate }}</span>
                  </div>

                </div>
                <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="sub-title"
                     v-html="experience.description">
                </div>
                <div v-if="index !== cvData.experiences.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.projects && cvData.projects.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Projects') }}</div>
              <div v-for="(project, index) in cvData.projects" :key="project.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `projects-${project.id}` }"
                   @mouseover="hoveredItemId = `projects-${project.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'projects', itemId: project.id })"
              >
                <div v-if="project.title" class="sub-title" style="font-weight: 600; font-size: calc(var(--global-font-size) + 2px);">{{ project.title }}</div>
                <div class="vertical-adjuster" style="padding-bottom: 3px; font-weight: 300;">
                  <div v-if="project.startDate" class="sub-title">{{ project.startDate }} - {{ project.endDate }}</div>
                </div>
                <div v-if="project.description && containsTextOutsideHtml(project.description)" class="sub-title"
                     v-html="project.description">
                </div>
                <div v-if="index !== cvData.projects.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.publications && cvData.publications.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Publications') }}</div>
              <div v-for="(publication, index) in cvData.publications" :key="publication.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `publications-${publication.id}` }"
                   @mouseover="hoveredItemId = `publications-${publication.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'publications', itemId: publication.id })"
              >
                <div v-if="publication.description && containsTextOutsideHtml(publication.description)" class="sub-title"
                     v-html="publication.description">
                </div>
                <div v-if="index !== cvData.publications.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.honors && cvData.honors.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Honors') }}</div>
              <div v-for="(honor, index) in cvData.honors" :key="honor.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `honors-${honor.id}` }"
                   @mouseover="hoveredItemId = `honors-${honor.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'honors', itemId: honor.id })"
              >
                <div v-if="honor.description && containsTextOutsideHtml(honor.description)" class="sub-title"
                     v-html="honor.description">
                </div>
                <div v-if="index !== cvData.honors.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Voluntary Activities') }}</div>
              <div v-for="(voluntary, index) in cvData.voluntaries" :key="voluntary.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `voluntaries-${voluntary.id}` }"
                   @mouseover="hoveredItemId = `voluntaries-${voluntary.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'voluntaries', itemId: voluntary.id })"
              >
                <div v-if="voluntary.description && containsTextOutsideHtml(voluntary.description)" class="sub-title"
                     v-html="voluntary.description">
                </div>
                <div v-if="index !== cvData.voluntaries.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
            <div v-if="cvData.hobbies && cvData.hobbies.length > 0" :style="{ color: cvData.resumeColor }" class="main-section-divs">
              <div class="section-title">{{ t('Hobbies') }}</div>
              <div v-for="(hobby, index) in cvData.hobbies" :key="hobby.id"
                   class="section-hoverable"
                   :class="{ highlighted: hoveredItemId === `hobbies-${hobby.id}` }"
                   @mouseover="hoveredItemId = `hobbies-${hobby.id}`"
                   @mouseleave="hoveredItemId = null"
                   @click="$emit('selectSectionItem', { section: 'hobbies', itemId: hobby.id })"
              >
                <div v-if="hobby.description && containsTextOutsideHtml(hobby.description)" class="sub-title"
                     v-html="hobby.description">
                </div>
                <div v-if="index !== cvData.hobbies.length - 1" class="padding-adder"></div>
              </div>
              <div class="horizontal-line"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
:root {
  --global-font-size: 10px;
}

.section-hoverable {
  transition: border 0.2s;
}

.section-hoverable.highlighted {
  border: 1px solid blue;
  background-color: rgb(177, 223, 223);
  cursor: pointer !important;
}

.resume {
  width: 595px;
  max-width: 98%;
  padding-right: 1%;
  padding-left: 1%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  text-align: start !important;
  min-height: 700px;
}

.center-container {
  display: flex;
  flex-direction: column;
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 10px;
}
.top-section{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.bottom-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    min-height: 100px;
}
.person-name {
  font-size: calc(var(--global-font-size) + 20px);
  font-weight: 700;
  padding-top: 10px;
}
.job-title {
    font-size: calc(var(--global-font-size) + 10px);
    padding-top: 5px;
}
.sub-title {
    font-size: calc(var(--global-font-size) + 2px);
    color: black;
    padding-left: 4px;
}
.descriptions {
    font-size: calc(var(--global-font-size) + 2px);
    color: black;
}
.details-left-side{
    flex-direction: column;
    margin: 5px;
    width: 65%;
}
.details-right-side{
    padding-top: calc(var(--global-font-size) + 10px);
    font-size: calc(var(--global-font-size) + 2px);
    flex-direction: column;
    margin: 10px;
    width: 35%;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    text-align: end;
}
.horizontal-line {
  height: 2px;
  background-color: var(--resume-color);
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.vertical-line {
  background-color: var(--resume-color);
  width: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  align-self: stretch;
}
.main-left-side{
    flex-direction: column;
    display: flex;
    width: 35%;
    padding-left: 2px;
}

.main-right-side{
    flex-direction: column;
    display: flex;
    width: 65%;
    padding-left: 5px;
}
.section-title {
    font-size: calc(var(--global-font-size) + 6px);
    font-weight: 700;
    padding-bottom: 1px;
    padding-bottom: 5px;
}
.horizontal-adjuster {
  display: flex;
  flex-direction: row;
}

.vertical-adjuster {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
}
.main-section-divs{
 margin: 5px;
}
.padding-adder{
    padding-bottom: 8px;
}
</style>

