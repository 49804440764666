<script setup lang="ts">
import { computed } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { getPreviousPath } from "@/composables/useNavigationHistory";
import { constructLangPrefix } from "@/utils/pathAdjuster";

const { t } = useI18n();

const props = defineProps({
  startProcessing: {
    type: Boolean,
    required: true,
  },
  premiumType: {
    type: String,
    required: false,
  },
  authUser: {
    type: Object,
    required: false,
  },
  processingFailed: {
    type: Boolean,
    required: true,
  },
  processingSuccess: {
    type: Boolean,
    required: true,
  },
  paymentActionRequired: {
    type: Boolean,
    required: true },
});
const processText = computed(() => {
  if (props.processingFailed) {return t("Payment.PaymentFailed");}
  if (props.processingSuccess) {return t("Payment.PaymentSuccessful");}
  if (props.paymentActionRequired) {return t("Payment.ActionRequired");}
  return t("Payment.ProcessPayment");
});

const detailText = computed(() => {
  if (props.processingFailed) {return t("Payment.ErrorText");}
  if (props.processingSuccess) {return t("Payment.SuccessText");}
  if (props.paymentActionRequired) {return t("Payment.ActionText");}
  return t("Payment.WaitTillProcess");
});

const emit = defineEmits(["close"]);

const closeComponent = () => {
  emit("close");
};

const router = useRouter();
const redirect = async () => {

  const langPrefix = constructLangPrefix();
  const previousPage = getPreviousPath(2);

  let redirectPath: string;

  if (previousPage === `${langPrefix}/payment`) {
    redirectPath = `${langPrefix}/account`;
  } else {
    redirectPath = previousPage ? `${langPrefix}${previousPage}` : `${langPrefix}/`;
  }

  await router.push(redirectPath);
  emit("close");
};

</script>

<template>
  <div class="overlay" @click.self="closeComponent">
    <div class="payment-card-container">
      <div class="payment-card">
        <div
          class="spinner-wrapper"
          :class="{
            'processing': startProcessing && !processingFailed && !processingSuccess && !paymentActionRequired,
            'success': processingSuccess,
            'action-required': paymentActionRequired
          }"
        >
          <div class="process-card">
            <div class="inner-card">
              <svg v-if="!processingFailed && !processingSuccess && !paymentActionRequired" class="payment-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM18 20H6V10h12v10z"
                ></path>
              </svg>
              <svg v-if="processingFailed" class="fail-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                ></path>
              </svg>
              <svg v-if="processingSuccess" class="success-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                ></path>
              </svg>
              <svg v-if="paymentActionRequired" class="action-required-icon" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 15a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 17zm1-7h-2V7h2z" fill="#FFD700"></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="process-text">
          {{ processText }}
        </div>
        <div class="detail-text">
          {{ detailText }}
        </div>
        <div v-if="processingFailed" class="button-divider">
          <button class="cancel-button" @click="closeComponent">{{ t("Cancel") }}</button>
          <button class="retry-button" @click="closeComponent">{{ t("Payment.TryAgain") }}</button>
        </div>
        <!-- <div v-if="processingSuccess || paymentActionRequired" class="button-divider"> -->
        <div v-if="processingSuccess" class="button-divider">
          <button class="retry-button" @click="redirect">{{ t("Close") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  align-items: center;
  justify-content: center;
}

.payment-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    margin: 20px;
}

.payment-card {
    width: 800px;
    height: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 4px solid #f2f2f2;
    justify-content: center;
    align-items: center;
}

.process-text {
    color: #030303;
    font-size: 24px;
    font-family: 'Montserrat';
    line-height: 28px;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.detail-text {
    color: #909090;
    font-size: 18px;
    font-family: 'Montserrat';
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
}

.spinner-wrapper {
    width: 137px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.spinner-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 4px solid #e74c3c;
}

.spinner-wrapper.processing::before {
    border-color: #aa93f3 transparent ;
    animation: spin 1s linear infinite;
}

.spinner-wrapper.success::before {
    border-color: #28a745;
}

.process-card {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-icon {
    color: #aa93f3;
    fill: #aa93f3;
    width: 50px;
    height: 50px;
    z-index: 1;
}

.fail-icon {
  color: #e74c3c;
  fill: #e74c3c;
  font-size: 67px;
  width: 67px;
  height: 64px;
}
.success-icon {
    color: #28a745;
    fill: #28a745;
    width: 50px;
    height: 50px;
}

.button-divider {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Montserrat';
    border-radius: 5px;
    cursor: pointer;
    border: none;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.cancel-button {
    background-color: #ccc3c3;
    color: #ffffff;
}

.retry-button {
    background-color: #5438da;
    color: #ffffff;
    width: 200px;
    text-align: center;
}

.spinner-wrapper {
    width: 137px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.spinner-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 4px solid #e74c3c;
}

.spinner-wrapper.processing::before {
    border-color: #aa93f3 transparent;
    animation: spin 1s linear infinite;
}

.process-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-icon {
    color: #aa93f3;
    fill: #aa93f3;
    width: 50px;
    height: 50px;
    z-index: 1;
}
.action-required-icon {
    color: #FFD700;
    fill: #FFD700;
    width: 50px;
    height: 50px;
}

.spinner-wrapper.action-required::before {
    border-color: #FFD700;
}

.action-button {
    background-color: #FFD700;
    color: #ffffff;
    width: 200px;
    text-align: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-dots {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 20px auto;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #5438da;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 -20px;
  animation: rotate 1.5s linear infinite;

}

.loading-dots .dot:nth-child(1) {
  transform: rotate(0deg) translateX(20px);
  animation-delay: 0s;
}
.loading-dots .dot:nth-child(2) {
  transform: rotate(45deg) translateX(20px);
  animation-delay: 0.15s;
}
.loading-dots .dot:nth-child(3) {
  transform: rotate(90deg) translateX(20px);
  animation-delay: 0.3s;
}
.loading-dots .dot:nth-child(4) {
  transform: rotate(135deg) translateX(20px);
  animation-delay: 0.45s;
}
.loading-dots .dot:nth-child(5) {
  transform: rotate(180deg) translateX(20px);
  animation-delay: 0.6s;
}
.loading-dots .dot:nth-child(6) {
  transform: rotate(225deg) translateX(20px);
  animation-delay: 0.75s;
}
.loading-dots .dot:nth-child(7) {
  transform: rotate(270deg) translateX(20px);
  animation-delay: 0.9s;
}
.loading-dots .dot:nth-child(8) {
  transform: rotate(315deg) translateX(20px);
  animation-delay: 1.05s;
}

</style>

