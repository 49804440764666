<script setup lang="ts">
import type { Ref } from "vue";
import { ref, computed, watch, nextTick } from "vue";

import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import {  analyticsCategories } from "@/composables/const";
import type { Skill , SectionScore } from "@/composables/types";
import { useAnalytics } from "@/composables/useAnalytics";
import { enhanceSkill, checkATS } from "@/services/enhanceService";
import { modifyLibraryEntry } from "@/services/libraryService";

import CvSectionScore  from "../../components/CvSectionScore.vue";
import EnhanceButton from "../../components/EnhanceButton.vue";
import SkillEnhanceModal from "../../components/SkillEnhanceModal.vue";
import { skillLevelMap } from "../../const";
import { initialStateSkill } from "../../utils/initialSectionStates";

const { t } = useI18n();
const { trackEvent } = useAnalytics();

const props = defineProps<{
  cvId: string,
  evaluated: boolean,
  scoreDetails: SectionScore | undefined
}>();

const { user } = useAuth();

const enhancedSkillsDiv: Ref<HTMLElement | null> = ref(null);
const store = useStore();
const showAdd = ref(true);
const isAdding = ref(false);
const showDescriptionModal = ref(false);
const enhanced = ref(false);
const atsChecked = ref(false);
const isLoading = ref(false);
const upgradePremiumVisible = ref(false);
const authModalVisible = ref(false);
const creditNotEnough = ref(false);

const closeModal = () => {
  showDescriptionModal.value = false;
};
const openModal = () => {
  showDescriptionModal.value = true;
};

const skillEnhancements = ref<string[]>([]);
const atsEnhancements = ref<string[]>([]);

watch(
  () => store.state.cvData,
  async (newcvData) => {
    try {
      await modifyLibraryEntry(props.cvId, newcvData);
    } catch (error) {
      console.error("Failed to update library entry:", error);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const selectedIndex = ref<number>(-1);
const levels = ref(["Beginner", "Intermediate", "Experienced", "Advanced", "Expert"]);
const explanation = ref<string>("");
const explanationKey = ref<string>("");

const levelExplanations: Record<string, string> = {
  Beginner: t("Beginner"),
  Intermediate: t("Intermediate"),
  Experienced: t("Experienced"),
  Advanced: t("Advanced"),
  Expert: t("Expert"),
};

const isLevelsToggled = ref(true);

const toggleLevelsSwitch = () => {
  isLevelsToggled.value = !isLevelsToggled.value;
  store.commit("updateShowSkillLevels", {  data: isLevelsToggled.value });
};

const showExplanation = (level: string) => {
  explanation.value = levelExplanations[level];
  explanationKey.value = level;
};

const hideExplanation = () =>  {
  explanation.value = "";
  explanationKey.value = "";
};

const addSkill = ref(initialStateSkill(uuidv4()));

const isFormValid = computed(() => {
  return addSkill.value.name.trim() !== "" ;
});

const selectLevel = (index: number) =>  {
  selectedIndex.value = index;

  if(selectedIndex.value>-1){addSkill.value.level = levels.value[selectedIndex.value];}
};

const skills = computed({
  get: () => store.state.cvData.skills,
  set: (value) => store.commit("updateCvSection", { section: "skills", data: value }),
});

const updateField = (fieldName: keyof Skill, event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  if (fieldName === "name" || fieldName === "level") {
    addSkill.value[fieldName] = DOMPurify.sanitize(value);
  }
  if(!isAdding.value){
    store.dispatch("updateCvItem", {
      section: "skills",
      itemId: addSkill.value.id,
      newData: addSkill.value,
    });
  }
};

const getSkillNames = (skills: Skill[]): string[] => {
  return skills.reduce((names, skill) => {
    if (skill.name) {
      names.push(skill.name);
    }
    return names;
  }, [] as string[]);
};

const discardEnhanced = () => {
  enhanced.value = false;
};

const discardAts = () => {
  atsChecked.value = false;
};

const getSkillEnhancement = async() => {
  if (!user.value.authUser) {
    authModalVisible.value = true;
    return;
  }
  if (user.value.details?.accountStatus !== "Premium") {
    trackEvent("button_click", {
      category: analyticsCategories.UPGRADE,
      action: "click",
      label: "skill_enhance_to_upgrade",
      value: 1,
    });
    upgradePremiumVisible.value = true;
    return;
  }
  const jobTitle = store.state.cvData.contact.jobTitle;
  if (jobTitle === "") {
    alert("Job title is required to check ATS");}
  isLoading.value = true;
  discardEnhanced();
  discardAts();
  try {
    trackEvent("button_click", {
      category: analyticsCategories.CV_ENHANCE,
      action: "click",
      label: "skill_enhance",
      value: 1,
    });
    const { result: response, error: enhanceError } = await enhanceSkill(JSON.stringify(store.state.cvData), getSkillNames(store.state.cvData.skills), t("Locale"), user.value.details.id || "");

    if (enhanceError?.value && enhanceError.value.indexOf("402") !== -1) {
      creditNotEnough.value = true;
      return;
    }
    skillEnhancements.value = response?.skillSuggestion || [];
    const { result: response2 } = await checkATS(JSON.stringify(store.state.cvData), getSkillNames(store.state.cvData.skills), t("Locale"), jobTitle);
    atsEnhancements.value = response2?.skillSuggestion || [];
    if(skillEnhancements.value.length || atsEnhancements.value.length){
      enhanced.value = true;
      atsChecked.value = true;
      nextTick(() => {
        if (enhancedSkillsDiv.value) {
          enhancedSkillsDiv.value.scrollIntoView({ behavior: "smooth", block: "end" });
        }
        openModal();
      });
    }
  } catch (e) {
    console.error("Error during enhancement or parsing:", e);
  } finally {
    isLoading.value = false;
  }

};

const updateSkills = (newSkill: string) =>  {
  skillEnhancements.value = skillEnhancements.value.filter(item => {
    return item !== newSkill;});
  atsEnhancements.value = atsEnhancements.value.filter(item => {
    return item !== newSkill;});
  store.dispatch("addCvItem", {
    section: "skills",
    item: { name: newSkill, id: uuidv4(), level: "" },
  });
  if(!skillEnhancements.value.length && !atsEnhancements.value.length){
    closeModal();
  }
};

watch(
  () => store.state.activeSection,
  (newActiveSection) => {
    console.log("newActiveSection", newActiveSection);
    if (newActiveSection?.sectionName === "skills") {

      const clickedItem = store.state.cvData.skills.find(
        (e: any) => e.id === newActiveSection.itemId,
      );
      if (clickedItem) {
        showAdd.value = false;
        isAdding.value = false;
        addSkill.value = { ...clickedItem };
      }
    }
  },
  { immediate: true },
);

</script>

<template>
  <AuthModal v-if="authModalVisible" @update:visible="authModalVisible = $event"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <UpgradePremium v-if="creditNotEnough" :premiumReasonText="t('NotEnoughCreditsTextPremium')" :premiumHeader="t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <div class="skills-container" @click="closeModal">
    <SkillEnhanceModal v-if="showDescriptionModal" :skillEnhancements="skillEnhancements" :atsEnhancements="atsEnhancements" @updateSkills="updateSkills" @closeModal="closeModal" @discardEnhanced="discardEnhanced" @discardAts="discardAts"/>
    <div class="score-container">
      <div class="details-container">
        <h2>{{ t('Skills') }}</h2>
        <p class="section-description">{{ t('SkillDetailsSection.FillInYourSkills') }}</p>
      </div>
      <CvSectionScore v-if="scoreDetails && evaluated" :scoreDetails="scoreDetails" />
    </div>
    <div class="button-wrapper">
      <div class="toggle-text-container">
        <div class="toggle-switch" :class="{ 'toggled-switch': isLevelsToggled }" @click="toggleLevelsSwitch">
          <div :class="{ toggle: true, toggled: isLevelsToggled }"></div>
        </div>
        <span class="toggle-description">{{ t('SkillDetailsSection.ShowLevelsOnResume') }}</span>
      </div>
      <button
        v-if="showAdd"
        @click="
          () => {
            selectedIndex = -1;
            isAdding = true;
            showAdd = false;
          }
        "
      >
        <font-awesome-icon icon="plus" />{{ t('SkillDetailsSection.AddASkill') }}
      </button>
      <div v-if="!showAdd">
        <div class="input-row">
          <div class="input-wrapper">
            <label for="skillName">{{ t('SkillDetailsSection.AddASkill') }}</label>
            <input
              id="skillName"
              type="text"
              :placeholder="t('SkillDetailsSection.EnterYourSkill')"
              :value="addSkill.name"
              @blur="updateField('name', $event)"
              @mouseleave="updateField('name', $event)"
            />
          </div>
          <div class="input-wrapper">
            <label for="skillLevel">{{ t('SkillDetailsSection.ExperienceLevel') }}</label>
            <div class="experience-levels">
              <div
                v-for="(level, index) in levels"
                :key="level"
                :class="{ 'selected-level': index <= selectedIndex }"
                class="level-indicator"
                @click="selectLevel(index)"
                @mouseover="showExplanation(level)"
                @mouseleave="hideExplanation"
              >
                <div v-if="explanation && explanationKey === level" class="level-explanation">{{ explanation }}</div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="action-button"
          :disabled="!isFormValid"
          @click="
            () => {
              if (isAdding) {
                store.dispatch('addCvItem', {
                  section: 'skills',
                  item: { ...addSkill, id: uuidv4() },
                });
                isAdding = false;
              }
              addSkill = initialStateSkill(uuidv4());
              showAdd = true;
              store.dispatch('setActiveSection', null);
            }
          "
        >
          {{ isAdding ? t("Add") : t("Save") }}
        </button>
        <button
          v-if="isAdding"
          type="button"
          class="action-button"
          @click="
            () => {
              selectedIndex = -1;
              showAdd = true;
              isAdding = false;
              initialStateSkill(uuidv4())
              store.dispatch('setActiveSection', null);
            }
          "
        >
          {{ t("Discard") }}
        </button>
      </div>
    </div>
    <div>
      <draggable v-if="showAdd" v-model="skills" itemKey="id" class="draggable-container" tag="div">
        <template #item="{ element, index }">
          <div :key="element.id" class="skill-item">
            <svg class="drag-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
            </svg>
            <div class="skill-content">
              <label class="skill-name">{{ element.name }}</label>
              <label class="skill-level">{{ t(element.level) }}</label>
            </div>
            <div class="skill-actions">
              <font-awesome-icon icon="edit" class="skill-action-icon" @click="
                () => {
                  selectedIndex = skillLevelMap.get(element.level) || 0;
                  showAdd = false;
                  addSkill = element;
                }
              "/>
              <font-awesome-icon
                icon="trash"
                class="skill-action-icon"
                @click="
                  store.dispatch('removeCvItem', {
                    section: 'skills',
                    index,
                  })
                "
              />
            </div>
          </div>
        </template>
      </draggable>
      <div style="margin: 20px;">
        <EnhanceButton :isLoading="isLoading" :width="'198px'" :height="'53px'" @enhance="getSkillEnhancement" />
      </div>
      <div v-if="enhanced || atsChecked" ref="enhancedSkillsContainer" class="enhanced-skills-container">
        <div class="enhanced-skills-content">
          <span>{{ t("SkillDetailsSection.EnhancementText") }}</span>
          <button class="enhanced-skills-button" @click.stop="openModal">
            <svg class="enhanced-skills-icon" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z"></path>
            </svg>
            <span>{{ t("SkillDetailsSection.EnhanceSkills") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.enhanced-skills-icon {
  font-size: 21px;
  width: 21px;
  height: 21px;
  color: #ffffff;
  fill: #ffffff;
}

.enhanced-skills-button {
  cursor: pointer;
  max-width: 180px;
  height: 40px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #5438da;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
}

.enhanced-skills-content {
  display: flex;
  flex-direction: column;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 16px;
  width: 60%;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.enhanced-skills-container {
  display: flex;
  width: 523px;
  height: 114px;
  background-color: #fafafa;
  border-radius: 26px;
  border: 2px solid #f21d0d;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.details-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.score-container {
  display: flex;
  flex-direction: row;
}

.drag-icon {
  color: #c2c2c2;
  fill: #c2c2c2;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.toggle-description {
  color: #909090;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 21px;
}

.toggle-text-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
}

.toggle-switch {
  cursor: pointer;
  display: block;
  position: relative;
  width: 40px;
  height: 20px;
  pointer-events: auto;
  border-radius: 40px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(84, 56, 218, 1);
  transition: background-color 0.3s ease;
}

.toggled-switch {
  background-color: rgba(84, 56, 218, 0.5);
}

.toggle {
  display: block;
  position: absolute;
  top: 50%;
  left: 3px;
  width: calc(50% - 6px);
  height: calc(100% - 6px);
  transform: translateY(-50%);
  transition: left 0.3s ease;
  border-radius: 40px;
  background-color: rgba(84, 56, 218, 1);
}

.toggled {
  left: calc(50% + 3px);
}

.experience-levels {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.level-indicator {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #eee;
  margin: 9px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.level-indicator.selected-level {
  background-image: radial-gradient(circle at center, #aea8f9 30%, rgba(255, 255, 255, 0.154) 70%);
}

.level-explanation {
  margin-top: 30px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #333;
  position: fixed;
}

.skill-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.skill-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-radius: 3%;
  background-color: #e1dfff;
  min-height: 2.6rem;
  padding: 10px;
}

.skill-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  gap: 15px;
}

.skill-action-icon {
  color: #909090;
}

.skill-name {
  margin-left: 20px;
}

.skill-level {
  color: #909090;
  margin-right: 20px;
}

.button-wrapper {
  text-align: left;
  margin-top: 15px;
}

.button-wrapper button {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 10px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 10px;
}

.button-wrapper button:hover {
  background-color: rgb(95, 82, 210, 0.7);
}

.button-wrapper button:focus {
  outline: none;
}

.skills-container {
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 20px;
  align-items: flex-start;
}

.skills-container h2 {
  text-align: start;
}

.skills-container p {
  text-align: start;
  color: #7d7d7d;
}

.input-row {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
  margin-top: 10px;
  align-items: flex-start;
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  font-weight: bold;
}

.input-wrapper input[type="text"],
.input-wrapper input[type="email"],
.input-wrapper input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
}

.input-wrapper select[id="level"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.continue-button {
  background-color: #aa93f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
}

.action-button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #dedede !important;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  margin-right: 10px;
  margin-top: 15px;
}

.draggable-container {
  margin-top: 25px;
}

.button-divider{
  flex-direction: row;
  display: flex;
  margin: 20px;
}
</style>
