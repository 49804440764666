<script setup lang="ts">
import type { Ref } from "vue";
import { ref, computed, watch } from "vue";

// eslint-disable-next-line import/no-unresolved
import "pdfjs-dist/build/pdf.worker";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";
import EvaluationResults from "@/components/EvaluationResults.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import { getComponentFeedbackWithScores, getBasicEvaluation } from "@/services/evaluateService";
import { processFile } from "@/utils/file";

import EvaluateToEhance from "./components/EvaluateToEnhance.vue";
const { t, locale } = useI18n();
GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.js";
import {  DEFAULT_SCORE, DEFAULT_EVALUATION_TEXT, JOB_TITLES_EN, JOB_TITLES_DE, JOB_TITLES_TR } from "./const";
import LoadingPage from "./cv-evaluation/cv-evaluation-loading/LoadingPage.vue";
import PdfFileUploader from "./cv-evaluation/PdfFileUploader.vue";

const props = defineProps({
  pdfData: String,
});

const { user } = useAuth();
//const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
const isEvaluating = ref(false);
const jobTitles = computed(() => {
  switch (locale.value) {
  case "de":
    return JOB_TITLES_DE;
  case "tr":
    return JOB_TITLES_TR;
  default:
    return JOB_TITLES_EN;
  }
});
const pdfBlobUrl = ref("");
const pdfFile: Ref<File | null> = ref(null);
const pdfData = ref("");
const showLoading = ref(false);
const showEvaluation = ref(false);
const selectedJobTitle: Ref<string> = ref("");
const showResults = ref(true);
const creditNotEnough = ref(false);
const invalidFileError = t("InvalidFileType");
const fileTooLargeError = t("FileTooLarge");
const basicEvaluationResult = ref({});
const showDetailedEvaluationPopUp = ref(false);
const showBasicEvaluation = ref(false);
const showDetailedEvaluation = ref(false);
const evaluationText = ref(DEFAULT_EVALUATION_TEXT);
const sectionScores = ref([]);
const score = ref(DEFAULT_SCORE);

const fetchFeedback = async (text: string, basicEvaluationResult: any) => {
  try {
    const { result: data } = await getComponentFeedbackWithScores(
      text,
      locale.value || "en",
      selectedJobTitle.value,
      user.value?.details?.id || "",
      basicEvaluationResult.value,
    );

    if (data && data.evaluation) {
      // Directly assign the evaluation object (no need to parse)
      basicEvaluationResult.value = data.evaluation;
      showDetailedEvaluation.value = true;
    } else {
      console.warn("No evaluation data found in the response.");
      showDetailedEvaluationPopUp.value = true;
    }

  } catch (error: any) {
    showDetailedEvaluationPopUp.value = true;
  }
};
  // try {
  //   const csrfResponse = await axios.get(API_URL +  "/csrf-token", { withCredentials: true });
  //   const csrfToken = csrfResponse.data.csrfToken;

//   const response: AxiosResponse<FeedbackResponse> = await axios({
//     method: "POST",
//     url: API_URL + "/componentsFeedbackWithScores",
//     data: JSON.stringify({ cvText: text, jobTitle: selectedJobTitle, language: t("Locale"), uid: user.value.details?.id || ""  }),
//     headers: {
//       "Content-Type": "application/json",
//       "X-CSRF-Token": csrfToken,
//     },
//     withCredentials: true,
//   });

//   if (!response.data) {
//     throw new Error("Network response was not ok");
//   }

//   const data = await response.data;

//   const componentsWithScores = JSON.parse(data.componentsWithScores);
//   score.value = parseInt(componentsWithScores.generalscore, 10);
//   evaluationText.value = componentsWithScores.evaluation;
//   sectionScores.value = componentsWithScores.sections;
//   showEvaluation.value = true;
//   showLoading.value = false;
// }
// catch (error: any) {
//   if(error?.response?.status === 402){
//     creditNotEnough.value = true;
//   }
// }
const   fetchBasicFeedback = async (text: string) => {
  try {
    const { result: data } = await getBasicEvaluation(
      text,
      locale.value,
      selectedJobTitle.value,
    );

    if (data) {
      basicEvaluationResult.value = data;
      if(user.value?.authUser){
        await fetchFeedback(text, basicEvaluationResult);
      }
      showBasicEvaluation.value = true;
    }
  } catch (error: any) {
    showDetailedEvaluationPopUp.value = true;
  }
};
const startEvaluation = async () => {
  if (pdfFile.value && !isEvaluating.value) {
    showLoading.value = true;
    try {
      const text: string = await processFile(pdfFile.value, invalidFileError, fileTooLargeError);
      pdfData.value = text;

      await fetchBasicFeedback(pdfData.value);
      showLoading.value = false;
      showEvaluation.value = true;

    } catch (error) {
      console.error("Evaluation failed:", error);
    }
  } else {
    alert("Please upload a CV first.");
  }

};

const handlePdfLoaded = (newPdf: File) => {
  if (pdfFile.value) {
    URL.revokeObjectURL(pdfBlobUrl.value);
  }

  pdfFile.value = newPdf;
  pdfBlobUrl.value = URL.createObjectURL(newPdf);
  showEvaluation.value = false;
  showLoading.value = false;
};

const filteredJobTitles = computed(() => {
  const query = selectedJobTitle.value.trim().toLowerCase();
  if (!query) {
    return [];
  }
  return jobTitles.value.filter(jobTitle => jobTitle.toLowerCase().includes(query));
});

const selectJobTitle = (jobTitle: string) => {
  selectedJobTitle.value = jobTitle;
  showResults.value = false;
};

const deselectJobTitle = () => {
  selectedJobTitle.value = "";
  showResults.value = true;
};
const handlePdfData = () => {
  if (props.pdfData) {
    fetchBasicFeedback(props.pdfData);
  }
};

const uploadNew = () => {
  pdfFile.value = null;
  pdfData.value = "";
  pdfBlobUrl.value = "";
  showEvaluation.value = false;
  showLoading.value = false;
};

watch(
  () => user.value.details,
  async (newAuthUser) => {
    if (newAuthUser?.id && basicEvaluationResult.value && pdfData.value && isEvaluating.value) {
      showLoading.value = true;
      showEvaluation.value = false;
      await fetchFeedback(pdfData.value, basicEvaluationResult);
      showEvaluation.value = true;
    }
  },
  { deep: true },
);
handlePdfData();
</script>

<template>
  <UpgradePremium v-if="creditNotEnough" :showFeatures="(user.details?.accountStatus === 'Free' || user.details?.accountStatus === 'Onhold') ? true :false" :premiumReasonText="(user.details?.accountStatus === 'Free' || user.details?.accountStatus === 'Onhold') ? t('NotEnoughCreditsTextFree') : t('NotEnoughCreditsTextPremium')" :premiumHeader="(user.details?.accountStatus === 'Free' || user.details?.accountStatus === 'Onhold') ? t('NotEnoughCreditsHeaderFree') : t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <div class="cv-evaluation-container">
    <div class="cv-inputs-container">
      <div class="left-panel">
        <div class="controls-container">
          <div class="search-bar">
            <input
              v-model="selectedJobTitle"
              type="text"
              :placeholder="t('cvEvaluation.DesiredJobTitle')"
              class="search-input">
          </div>
          <button v-if="selectedJobTitle" class="deselect-button" @click="deselectJobTitle">X</button>
          <ul v-if="showResults && filteredJobTitles.length" class="results-list">
            <li
              v-for="jobTitle in filteredJobTitles"
              :key="jobTitle"
              class="result-item"
              @click="selectJobTitle(jobTitle)"
            >
              {{ jobTitle }}
            </li>
          </ul>
          <button
            class="evaluate-button"
            :class="{ disabled: isEvaluating }"
            :disabled="isEvaluating"
            @click="startEvaluation"
          >
            {{ t('cvEvaluation.EvaluateButton') }}
          </button>
        </div>
        <div v-if="!pdfBlobUrl && !showLoading">
          <PdfFileUploader @pdf-loaded="handlePdfLoaded" />
        </div>
        <div v-if="pdfBlobUrl" class="pdf-display">
          <iframe :src="pdfBlobUrl" width="100%" height="100%" />
          <button class="upload-new-button" @click="uploadNew">{{ t("cvEvaluation.UploadNew") }}</button>
        </div>
      </div>
      <div class="right-panel">
        <div v-if="!showLoading && !showEvaluation" >
          <p class="right-panel-header">{{ t('cvEvaluation.BoostMessage') }}</p>
        </div>
        <div v-if="showLoading && !showEvaluation" class="loading-message">
          <LoadingPage />
        </div>
        <div v-if="!showLoading && showEvaluation">
          <div v-if="selectedJobTitle" class="selected-job-title">
            <h3>{{ t('cvEvaluation.SelectedJobTitle') }}{{ selectedJobTitle }}</h3>
          </div>
          <EvaluationResults
            :showLoading="showLoading"
            :showDetailedEvaluation="showDetailedEvaluation"
            :showBasicEvaluation="showBasicEvaluation"
            :selectedJobTitle="selectedJobTitle"
            :basicEvaluationResult="basicEvaluationResult"
            :score="score"
            :evaluationText="evaluationText"
            :sectionScores="sectionScores"
            :pdfData="pdfData"
          />
          <div v-if="user.authUser" style="margin: 20px;">
            <EvaluateToEhance :pdfData="pdfData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.job-select {
  cursor: pointer;
  width: 209px;
  height: 58px;
  color: #3d3d3d;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 24px;
  outline: none;
  flex: 0 0 auto;
}

.evaluate-button {
  cursor: pointer;
  width: 209px;
  height: 58px;
  padding: 0px 8px;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #5438da;
  color: #ffffff;
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 26px;
  outline: none;
  flex-shrink: 0;
  margin-left: auto;
}

.controls-container {
  justify-content: start;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-top: 70px;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
}

.evaluate-button:hover {
  background-color: #9c27b0;
}

.cv-evaluation-container {
  display: flex;
  flex-direction: column;
}

.cv-inputs-container {
  display: flex;
}

.left-panel {
  position: relative;
  width: 50%;
  background-color: #ffffff;
  height: 120vh;
  flex-direction: column;
  overflow-y: auto;
}

.right-panel {
  position: relative;
  width: 50%;
  background-color: #f2f2f2;
  height: 120vh;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 30px;
}

.left-panel-header {
  font-size: 30px;
  color: #0000FF;
}

.right-panel-header {
  color: #5438da;
    font-size: 32px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 42px;
    text-align: center;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.panel-spacing {
  width: 20px;
}

select {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #6a1b9a;
  background-color: white;
  margin-bottom: 15px;
  font-size: 16px;
  color: #6a1b9a;
  flex: 1;
}

option {
  color: #6a1b9a;
}

.file-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin-bottom: 100px;
    margin-top: 100px;
}
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon {
    fill: #909090;
    width: 57px;
    height: 58px;
}
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 646px;
    height: 360px;
    background-color: #ffffff;
    border-radius: 18px;
    border: 2px dashed #505050;
    box-sizing: border-box;
}
.text {
    color: #909090;
    text-align: center;
}
.large {
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 31px;
}
.small {
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 23px;
}
.button-row {
    cursor: pointer;
    width: 154px;
    height: 56px;
    padding: 0px 8px;
    border: 2px solid #5438da;
    background-color: transparent;
    color: #5438da;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 19px;
    border-radius: 8px;
    outline: none;
}

.pdf-display {
  height: 297mm;
  border: 1px solid #ccc;
  margin: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  width: 350px;
}

.search-input {
  flex-grow: 1;
  height: 56px;
  padding: 0 12px;
  padding-right: 40px;
  font-size: 14px;
  border: 1px solid #5438da;
  border-radius: 8px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235438da"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
}

.search-button {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #5438da;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.results-list {
  width: 350px;
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
  text-align: start;
}

.result-item {
  padding: 10px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 14px;
  color: #333;
}

.result-item:hover {
  background-color: #f0f0f0;
}

.result-item:active {
  background-color: #e0e0e0;
}
.deselect-button {
  background: none;
  border: none;
  color: #909090;
  font-size: 18px;
  cursor: pointer;
  margin-left: -50px;
  padding: 0;
}

.upload-new-button{
  position: relative;
  cursor: pointer;
  width: 228px;
  height: 58px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 40px;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0);
  color: #909090;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 21px;
  outline: none;
}
.evaluate-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  pointer-events: none;
}
.selected-job-title{
  font-size: 22px;
}

</style>