<script setup lang="ts">
defineProps<{
  buttonText: string;
}>();

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>

<template>
  <button class="evaluate-button-mobile" @click="handleClick">
    <span>{{ buttonText }}</span>
  </button>
</template>

<style>

.evaluate-button-mobile {
    cursor: pointer;
    width: 200px;
    height: 48px;
    padding: 0px 8px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #5438da;
    color: #ffffff;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 24px;
    outline: none;
    margin-left: 0;
    margin-bottom: 70px;
}
</style>
