<script setup lang="ts">
import { defineProps, ref, watch, withDefaults } from "vue";

import { useEvaluationUtils } from "@/utils/getEvaluationScore";

const { getScoreColor, getScoreRange } = useEvaluationUtils();

const props = withDefaults(
  defineProps<{
    score: number;
    showText?: boolean;
    width?: number;
  }>(),
  {
    width: 100,
  },
);
const showText = props.showText !== false;

const animatedScore = ref(0);

const animateScore = (finalScore: number) => {
  const animationDuration = 1000; // Total animation duration in milliseconds
  const frameDuration = 10; // Frame duration in milliseconds
  const totalFrames = animationDuration / frameDuration;
  const scoreIncrement = (finalScore - animatedScore.value) / totalFrames;

  let currentFrame = 0;

  const animationInterval = setInterval(() => {
    animatedScore.value += scoreIncrement;
    currentFrame++;

    if (currentFrame >= totalFrames) {
      animatedScore.value = finalScore;
      clearInterval(animationInterval);
    }
  }, frameDuration);
};

// Watch for changes in the score prop to trigger animation
watch(() => props.score, (newScore) => {
  animateScore(newScore);
});

// Initial animation on mount
animateScore(props.score);

const scoreToDashArray = (score: number): string => {
  const circumference = 283;
  const filledLength = (score / 100) * circumference;

  return `${filledLength} ${circumference - filledLength}`;
};

</script>

<template>
  <div class="cv-evaluation-section-scores">
    <div class="score-card" :style="{ width: props.width + 'px' }">
      <svg class="score-circle" viewBox="0 0 100 100">
        <circle class="circle-background" cx="50" cy="50" r="45"></circle>
        <circle
          class="circle-score"
          :stroke="getScoreColor(animatedScore.toString())"
          cx="50"
          cy="50"
          r="45"
          :stroke-dasharray="scoreToDashArray(animatedScore)"
          transform="rotate(-90 50 50)"
        ></circle>
        <text class="score-text" x="50" y="55" dominant-baseline="middle" text-anchor="middle">{{ Math.round(animatedScore) }}%</text>
      </svg>
      <div v-if="showText" class="score-range">{{ getScoreRange(animatedScore.toString()) }}</div>
    </div>
  </div>
</template>

<style scoped>
.cv-evaluation-section-scores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.score-card {
  text-align: center;
  margin-bottom: 20px;
}

.circle-background {
  fill: none;
  stroke: #f2f2f2;
  stroke-width: 10;
}

.circle-score {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
  transition: stroke-dasharray 0.5s ease;
}

.score-text {
  font-size: 24px;
  fill: #333;
}

.score-range {
  margin-top: 10px;
}

.score-range.Poor {
  color: #f21d0d;
}

.score-range.Fair {
  color: #ff4f17;
}

.score-range.Average {
  color: #ffc107;
}

.score-range.Good {
  color: #67bd6a;
}

.score-range.Excellent {
  color: #429846;
}
</style>